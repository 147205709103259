/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HomeIcon from '@material-ui/icons/Home';
import { Upload } from 'antd';
import _ from "lodash";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ChartSkeleton from '../../components/ChartSkeleton';
import { validateProduct1 } from '../../resources/Validations';
import services from '../../services/index';
import ProductUsageViewsChart from './ProductUsageViewChart';
import { getFileDimensions } from '../../helper/getFileDimensions';
import { getS3FileDimensions } from '../../helper/getS3FileDimensions';
import { uploadFileOnS3 } from '../../helper/s3Uploader';
import { renameS3File } from '../../helper/renameS3File';
toast.configure();

const couponImage = require('../../assets/images/logo-placeholder.png');

const ProductCouponDetails = ({ setPage }) => {
  const { client } = useSelector(state => state);
  const { campaign, category } = client;
  const [coupon, setCoupon] = useState(client.coupon);
  const [name, setName] = useState([]);
  const [price1, setPrice] = useState([]);
  const [dec, setDec] = useState([]);
  const [img, setImg] = useState();
  const [cate, setCategorys] = useState('');
  const [subCate, setSubCategorys] = useState('');
  const [codes, setCode] = useState([]);
  const [ProductCode, setProductCode] = useState([]);
  const [usage, setUsage] = useState([]);
  const [views, setViews] = useState([]);
  const [editCoupon, setEditCoupon] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [ProductUrl, setProductUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiFiles, setApiFiles] = useState([]);

  const [newUploadedFile, setNewUploadedFile] = useState([]);
  const [oldEditedFile, setOldEditedFile] = useState([]);

  const notify = (type, text) => toast[type](text, {
    position: toast.POSITION.TOP_RIGHT
  });

  const id = localStorage.getItem("producrid")

  const getCoupon = async () => {
    await services.CampaignServices.getProduct(id)
      .then(res => res.json())
      .then(res => {
        localStorage.setItem('memoriesdata', JSON.stringify(res.data))
        setName(res.data.name)
        setPrice(res.data.price)
        setDec(res.data.description)
        setApiFiles(res?.data?.images)
        // setFileList(res?.data?.images.map((data) => data.images_url))
        setImg(res?.data?.images)
        setProductCode(res.data.code)
        setCategorys(res.data.category)
        setSubCategorys(res.data.subcategory)
        setCode(res.data.code)
        setProductUrl(res.data.url)
        setUsage(res.usage)
        setViews(res.views)
      })
      .catch(err => console.log('getCouponn', err));
  }

  const handleEdit = async () => {
    setLoading(true);

    if (editCoupon?.url) {
      editCoupon.url = editCoupon.url.toLowerCase()
    }

    let dimensions
    if (apiFiles?.length === 0) {
      dimensions = await getFileDimensions(newUploadedFile[0]);
    } else {
      dimensions = await getS3FileDimensions(apiFiles[0]);
    }

    let renamedUrl = [];
    let newUrl = [];
    let oldUrl = img.map((file) => { return ({ images_url: file.images_url }) });

    oldEditedFile && await renameS3File(oldEditedFile, dimensions, "product")
      .then((res) => {
        if (res.status) {
          renamedUrl.push(...res.data)
        } else {
          notify("error", "Something went to wrong.");
          setLoading(false);
          setErrorMessage("");
        }
      })
      .catch((error) => {
        notify("error", "Something went to wrong.");
        console.log("image upload error", error);
        setLoading(false);
        setErrorMessage("");
      })

    newUploadedFile && await uploadFileOnS3(newUploadedFile, dimensions, "product")
      .then((res) => {
        res.forEach((item) => {
          newUrl.push({ images_url: item.Location })
        })
      })
      .catch((error) => {
        notify("error", "Something went to wrong.");
        console.log("image upload error", error);
        setLoading(false);
        setErrorMessage("");
      });

    let files = []

    if (oldEditedFile.length === 0 && newUploadedFile.length !== 0) {
      if (apiFiles.length === 0) {
        files.push(...newUrl)
      } else {
        files.push(...oldUrl)
        files.push(...newUrl)
      }
      editCoupon.images = files
    }
    if (oldEditedFile.length !== 0 && newUploadedFile.length !== 0) {
      files.push(...renamedUrl)
      files.push(...newUrl)
      editCoupon.images = files
    }
    if (oldEditedFile.length !== 0 && newUploadedFile.length === 0) {
      files.push(...renamedUrl)
      editCoupon.images = files;
    }

    services.CouponServices.updateProduct(id, editCoupon)
      // services.CouponServices.updateProduct(id, editCoupon, payload)
      .then(res => res.json())
      .then(response => {
        setPage('product-coupon-details');
        getCoupon();
        setNewUploadedFile([]);
        setOldEditedFile([]);
        if (response.err) {
          notify('error', response.message)
          setLoading(false)
          return;
        }
        notify('success', 'Product updated successfully.');
        setShowEdit(false);
        setLoading(false);
      })
      .catch((err) => {
        notify('error', 'Something went to wrong.')
        console.log("err", err)
        setLoading(false)
      })
  }

  const handleDelete = () => {
    services.CouponServices.deleteProduct(id)
      .then(response => {
        setPage('ProductCampaign');
        setShowDelete(false);
      })
      .catch(err => console.log('Delete Product error: ', err));
  }

  useEffect(() => {
    getCoupon();
    setEditCoupon({
      ...editCoupon,
      _id: id,
    });
  }, []);

  let newCouponStartDate;
  if (moment(campaign.campaign_start_date).unix() > moment().unix()) {
    newCouponStartDate = moment(campaign.campaign_start_date).subtract(1, 'days');
  } else {
    newCouponStartDate = moment().subtract(1, 'days');
  }

  const onCloseModal = () => {
    setShowEdit(false);
    setFileList(img);
    setLoading(false);
    setNewUploadedFile([]);
  }

  let tempFile = [];
  const propsUploadFile = {
    onRemove: (file) => {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item !== file)
      );

      const filteredImages = newUploadedFile.filter(
        (item) => item !== file
      );
      setNewUploadedFile([...filteredImages])
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        setErrorMessage("File size exceeds the maximum allowed size (50MB).");
        return false;
      } else {
        setErrorMessage("");
      }

      // If it's an image or video, add it to the fileList
      if (isImage || isVideo) {
        setFileList((prevFileList) => [...prevFileList, file]);
        tempFile.push(file);
        setNewUploadedFile([
          ...newUploadedFile, ...tempFile
        ])
      } else {
        setErrorMessage(
          "Invalid file type. Only images and videos are allowed."
        );
        return false;
      }
      return false;
    },
    fileList,
    multiple: true,
    showUploadList: false,
  };

  const handlePreview = (file) => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    if (isImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result;
      };
      reader.readAsDataURL(file);
    } else if (isVideo) {
    }
  };

  const handleRemoveNewFile = (file) => {
    setFileList((prevFileList) => prevFileList.filter((item) => item !== file));
    const filteredImages = newUploadedFile.filter((item) => item !== file);
    setNewUploadedFile([...filteredImages]);
    setErrorMessage("");
  };

  const handleRemoveOldFile = (file) => {
    const filteredImages = apiFiles.filter((item) => item.id !== file.id);
    setApiFiles(filteredImages);
    setOldEditedFile(filteredImages);
  }

  useEffect(() => {
    if (newUploadedFile.length + apiFiles.length > 5) {
      setErrorMessage("You can only upload up to 5 files.");
    } else {
      setErrorMessage("");
    }
  }, [newUploadedFile, apiFiles]);

  return (
    <>
      <>
        <div className="row">
          <div className="col">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="javascript:;" onClick={() => setPage('dashboard')}>
                <HomeIcon />
              </Link>
              <Link color="inherit" href="javascript:;" onClick={() => setPage('ProductCampaign')}>
                <Typography color="primary">Products</Typography>
              </Link>
              <span color="inherit">
                <Typography color="primary">Product Details</Typography>
              </span>
            </Breadcrumbs>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            {coupon ? (
              <Card>
                <CardHeader
                  title="Product Details"
                  subheader="Product Details"
                  action={
                    <>
                      <IconButton aria-label="edit campaign" title="Edit Product" onClick={() => {
                        setShowEdit(true);
                        setEditCoupon({ ...editCoupon, category: cate, code: ProductCode, description: dec, name: name, price: price1, subcategory: subCate, url: ProductUrl })
                        setSubCategories(category.filter(sub => sub.category === cate));
                        setApiFiles(img)
                      }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete campaign" title="Delete Product" onClick={() => setShowDelete(true)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  }
                />
                <CardContent>
                  <div className="row">
                    <div className="col-12 col-lg-3">
                      {
                        img ?
                          (img[0]?.fileType === "video" ? <video src={img[0]?.images_url} width="auto" style={{ width: "auto", height: "120px" }} controls></video> :
                            <img src={img[0]?.images_url} alt={img[0]?.images_url} style={{ width: "auto", height: "120px" }} />) :
                          <img src={couponImage} alt="prev" style={{ width: "auto", height: "120px" }} />
                      }
                    </div>
                    <div className='col-12 col-lg-9'>
                      <div className='row'>
                        <div className="col-12 col-md-6 col-xl-4 mt-lg-0 mt-3">
                          <h6><u>Product Name</u></h6>
                          <p>{name}</p>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4 mt-lg-0 mt-md-3 mt-0">
                          <h6><u>Product Description</u></h6>
                          <p>{dec}</p>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <h6><u>Product Price</u></h6>
                          <p>{price1}</p>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <h6><u>Category - Subcategory</u></h6>
                          <p>{cate} - {subCate || 'no subcategory'}</p>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <h6><u>Product Code</u></h6>
                          <p>{ProductCode}</p>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <h6><u>Product Url</u></h6>
                          <p>{ProductUrl}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <ChartSkeleton />
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <Card>
              <CardHeader
                title="Usage and  Views"
                subheader="Daily trend for this Product." />
              <CardContent>
                <ProductUsageViewsChart data={{ coupon, usage, views }} height='300px' />
              </CardContent>
            </Card>
          </div>
        </div>
      </>
      <Modal centered show={showEdit} onHide={() => onCloseModal()} size="lg" className='edit_product_modal'>
        <Modal.Header closeButton>
          <Modal.Title>EDIT A PRODUCT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="couponForm" encType="multipart/form-data">
            <div className="row mt-3">
              <div className="col-lg-6 col-12">
                <Form.Label>PRODUCT NAME</Form.Label>
                <Form.Control
                  className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                  placeholder="Product Name"
                  type="text"
                  defaultValue={editCoupon.name || name}
                  onChange={(event) => setEditCoupon({ ...editCoupon, name: event.target.value })}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className='col-12'>
                <Form.Label>Product Description</Form.Label>
                <textarea
                  name="description"
                  onChange={(event) => setEditCoupon({ ...editCoupon, description: event.target.value })}
                  className="form-control"
                  rows="3"
                  defaultValue={editCoupon.description || dec}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-7 col-12'>
                <Form.Label>Product Code</Form.Label>
                <Form.Control
                  className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                  placeholder="Product Code"
                  type="text"
                  defaultValue={editCoupon.code || codes}
                  onChange={(event) => setEditCoupon({ ...editCoupon, code: event.target.value })}
                />
              </div>
              <div className='col-lg-5 col-12 mt-3 mt-lg-0'>
                <Form.Label>Product Price</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  style={{ height: 48 }}
                  className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                  placeholder="Product Price"
                  defaultValue={editCoupon.price || price1}
                  onChange={(event) => setEditCoupon({ ...editCoupon, price: event.target.value })}
                />
              </div>
              <div className='col-lg-6 col-12 mt-3' controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  style={{ height: 48 }}
                  defaultValue={editCoupon.category || cate && cate}
                  onChange={(event) => {
                    setEditCoupon({ ...editCoupon, category: event.target.value, subcategory: '' })
                    setSubCategories(category.filter(sub => sub.category === event.target.value))
                  }}
                >
                  <option>Choose a category</option>
                  {category?.map((cat, index) => (<option key={index} value={cat.category}>{cat.category}</option>))}
                </Form.Control>
              </div>
              <div className='col-lg-6 col-12 mt-3' controlId="subcategory">
                <Form.Label>Sub-Category</Form.Label>
                <Form.Control
                  as="select"
                  style={{ height: 48 }}
                  value={editCoupon.subcategory || subCate && subCate}
                  onChange={(event) => setEditCoupon({ ...editCoupon, subcategory: (event.target.value) })}
                >
                  {<option value="" selected>Choose a category first</option>}
                  {!_.isEmpty(editCoupon.category) && _.isEmpty(subCategories?.[0]?.subcategory) && <option>There are no subcategories</option>}
                  {subCategories[0]?.subcategory.map((subcategory, index) => (<option key={index} value={subcategory}>{subcategory}</option>))}
                </Form.Control>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <Form.Label>Product URL</Form.Label>
                <Form.Control
                  className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                  type="url"
                  placeholder="https://example.com"
                  pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                  defaultValue={editCoupon.url || ProductUrl}
                  onChange={(event) => setEditCoupon({ ...editCoupon, url: event.target.value })}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className="col-12">
                <div>
                  <Form.Label>
                    Product Image / Video (50 MB max.)
                  </Form.Label>
                  <Upload
                    {...propsUploadFile}
                    onPreview={handlePreview}
                    accept="image/*,video/*"
                  >
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Button
                        className="choose_file_button"
                        onClick={() => setErrorMessage("")}
                      >
                        Choose file
                      </Button>
                    </div>
                  </Upload>
                  {errorMessage && (
                    <div className="mt-1 text-danger">
                      {errorMessage}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-12 col-12 mt-3">
                <Form.Label>Product Preview</Form.Label>
                {(apiFiles?.length === 0 && newUploadedFile.length === 0) && <img src={couponImage} alt="Coupon" width="150px" height="100px" />}
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  {
                    apiFiles.map((file) => {
                      const { images_url, fileType } = file;
                      const isImage = fileType === "image";
                      const isVideo = fileType === "video";

                      return (
                        <>
                          {isImage && (
                            <div className="position-relative d-inline-block">
                              <div className="upload_preview_cancel_icon"
                                onClick={() => handleRemoveOldFile(file)}
                              > X</div>
                              <img
                                src={images_url}
                                alt={file.name}
                                className="border rounded"
                                style={{ width: "auto", height: "100px" }}
                              />
                            </div>
                          )}
                          {(
                            isVideo &&
                            <div className="position-relative d-inline-block">
                              <div className="upload_preview_cancel_icon"
                                onClick={() => handleRemoveOldFile(file)}
                              >X</div>
                              <video
                                controls
                                src={images_url}
                                className="border rounded"
                                style={{ width: "auto", height: "100px" }}
                              />
                            </div>
                          )}
                        </>
                      )
                    })
                  }
                  {
                    newUploadedFile.length !== 0 && newUploadedFile.map((file) => {
                      return (
                        <>
                          {file.type.startsWith("image/") ? (
                            <div className="position-relative">
                              <div
                                className="upload_preview_cancel_icon"
                                onClick={() => handleRemoveNewFile(file)}
                              >
                                X
                              </div>
                              <img
                                src={URL.createObjectURL(file)}
                                alt={file.name}
                                className="border rounded"
                                style={{ width: "auto", height: "100px" }}
                              />
                            </div>
                          ) : (
                            <div className="position-relative">
                              <div
                                className="upload_preview_cancel_icon"
                                onClick={() => handleRemoveNewFile(file)}
                              >
                                X
                              </div>
                              <video
                                controls
                                src={URL.createObjectURL(file)}
                                className="border rounded"
                                style={{ width: "auto", height: "100px" }}
                              />
                            </div>
                          )}
                        </>
                      )
                    })
                  }
                </div>
                {/* <Row gutter={16}>
                  {img?.map(item => {
                    const { _id, images_url, fileType } = item;
                    const isImage = fileType === "image";
                    const isVideo = fileType === "video";
                    return (
                      <Col className="gutter-row" key={_id} style={{ marginBottom: '16px' }}>
                        {isImage && <img src={images_url} className='border rounded' alt={`Image ${_id}`} style={{ width: 'auto', height: '100px' }} />}
                        {isVideo && (
                          <video controls src={images_url} className='border rounded' style={{ width: 'auto', height: '100px' }}></video>
                        )}
                      </Col>
                    );
                  })}
                </Row> */}
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={validateProduct1(editCoupon) || loading || (apiFiles.length === 0 && newUploadedFile.length === 0) || errorMessage} onClick={() => handleEdit()}>
            {loading && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className='mr-2'
            />}
            Update Product
          </Button>
          <Button variant="light" onClick={() => onCloseModal()} style={{ marginLeft: '20px' }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={showDelete} onHide={() => setShowDelete(false)} size="md">
        <Modal.Header closeButton variant="danger">
          <Modal.Title>DELETE PRODUCT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete <b>"{name}"</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDelete()}>
            Yes
          </Button>
          <Button variant="light" onClick={() => setShowDelete(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProductCouponDetails;
