import config from "../config";

const token = localStorage.getItem('token');

const getInvoiceUsage = async client_id => {
  return await fetch(`${config.apiUrl}/use/${client_id}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/jsoon',
      Authorization: `Bearer ${token}`,
    }
  });
};

const getCouponUsage = async (client_id, coupon_id) => {
  return await fetch(`${config.apiUrl}/use/${client_id}/${coupon_id}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      
    }
  })
};

export default {
  getCouponUsage,
  getInvoiceUsage
};