import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

const MonthlyUsageViewsChart = ({ data }) => {
  let months = [];
  let monthsData = [];

  for (var i = 0; i < 3; i++) {
    const start = moment().subtract(i, 'months').startOf('month').format('M/DD/Y');
    const end = moment().subtract(i, 'months').endOf('month').format('M/DD/Y');
    const usage = data.filter(use => moment(use.created_at).isBetween(start, end));

    months.push(moment().subtract(i, 'months').format('MMMM'));
    monthsData.push(usage.length);
  }

  const options = {
    chart: {
      type: 'column'
    },
    colors: ['#1de9b6', '#1dc4e9', '#A389D4', '#899FD4'],
    title: {
      text: ''
    },
    xAxis: {
      categories: months,
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    series: [{
      name: 'Monthly Redemptions',
      data: monthsData
    }],
    credits: {
      enabled: false
   }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default MonthlyUsageViewsChart;