import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
// import TablePagination from "@material-ui/core/TablePagination";
import { EyeOutlined, RedoOutlined } from '@ant-design/icons';
import { setCurrentCampaign } from '../../store/actions/index';

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    // marginLeft: '5px'
    // border: 'none',
  },
  button: {
    marginLeft: "10px",
  },
  view_td: {
    [theme.breakpoints.down('lg')]: {
      width: '264px'
    },
  }
}));

const PromotionTable = ({ headings, data, period, setPage, enable_disable_promotion }) => {

  const [page, setPage1] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const classes = useStyles();
  const dispatch = useDispatch();

  const setPromotion = (campaign) => {
    localStorage.setItem("campaignid", campaign._id)
    dispatch(setCurrentCampaign(campaign));
    setPage('promotion-details');
  };

  const handleChangePage = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage1(0);
  };

  const DateDiff = (date_string) => {

    var new_d = date_string.replace(/-/g, '/');
    var new_obj = new_d.split(' ');
    var obj = new Date(new_obj[0]);
    var formatted_Date = ((obj.getMonth() + 1) + '/' + obj.getDate() + '/' + obj.getFullYear());
    var formatted_time = new_obj[1].split('.')[0];
    formatted_Date = formatted_Date + ' ' + formatted_time;
    var d1 = new Date(formatted_Date)
    var d2 = new Date(); // now
    var diff = d2 - d1, sign = diff < 0 ? -1 : 1, milliseconds, seconds, minutes, hours, days;
    diff /= sign; // or diff=Math.abs(diff);
    diff = (diff - (milliseconds = diff % 1000)) / 1000;
    diff = (diff - (seconds = diff % 60)) / 60;
    diff = (diff - (minutes = diff % 60)) / 60;
    days = (diff - (hours = diff % 24)) / 24;

    if (sign === 1) {
      if (days > 0) {
        return days + " days " + hours + " hours " + minutes + " minutes ago"
      } else {
        return hours + " hours " + minutes + " minutes ago";
      }
    } else {
      if (days > 0) {
        return "in " + days + " days " + hours + " hours " + minutes + " minutes ";
      } else {
        return "in " + hours + " hours " + minutes + " minutes ";
      }
    }
  }

  return (
    <>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headings.map((head, index) => <StyledTableCell key={index} align="left">{head}</StyledTableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((campaign) => {
              const date = (period === 'future') ? campaign.campaign_start_date : campaign.campaign_expiration_date;
              return (
                <StyledTableRow hover key={campaign._id}>
                  <StyledTableCell align="left">
                    {campaign.campaign_name}
                  </StyledTableCell>
                  {/*  <StyledTableCell align="left">{campaign && campaign.coupons && campaign.coupons.length}</StyledTableCell> */}
                  <StyledTableCell align="left"> {campaign && campaign.enabled ? 'Yes' : 'No'} </StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(date).format("MMM Do YYYY")} <small> -  ({DateDiff(date)}) </small>
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.view_td}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setPromotion(campaign)}
                      className={classes.button}
                      // startIcon={<SearchIcon />}
                      startIcon={<EyeOutlined />}
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      color="Secondary"
                      // onClick={() => enable_disable_promotion(campaign._id)}
                      onClick={() => {
                        enable_disable_promotion(campaign._id, campaign.enabled);
                      }} //this is where it needs to be passed
                      className={classes.button}
                      // startIcon={<SearchIcon />}
                      startIcon={<RedoOutlined />}
                    >
                      {campaign && campaign.enabled ? 'Disable' : 'Enable'}
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
    rowsPerPageOptions={[5, 25, 100]}
    component="div"
    count={data.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onChangePage={handleChangePage}
    onChangeRowsPerPage={handleChangeRowsPerPage}
  /> */}
    </>
  );
}

export default PromotionTable;
