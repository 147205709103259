import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import ContactUs from './components/FrontEnd/ContactUs';
import Discovery from './components/FrontEnd/Discovery';
import Home from './components/FrontEnd/Home';
import Privacy from './components/FrontEnd/privacy';
import Terms from './components/FrontEnd/terms';
import Layout from './components/Layout';
import ThankyouPage from './components/ThankyouPage/ThankyouPage';
import MainPage from './pages/MainPage/MainPage';

const App = () => {
  return (
    <Switch>
      <HashRouter>
        <Route exact path='/auth' component={MainPage} />
        <Route exact path='/auth/:id' component={MainPage} />
        <Route exact path={`/`} component={Home} />
        <Route exact path={`/Thankyou`} component={ThankyouPage} />
        <Route exact path={`/contact`} component={ContactUs} />
        <Route exact path={`/terms`} component={Terms} />
        <Route exact path={`/privacy`} component={Privacy} />
        <Route exact path={`/discovery`} component={Discovery} />
        <Route exact path='/app' render={props => <Layout {...props} loadPage="home" />} />
        {/* <Route component={MainPage} /> */}
      </HashRouter>
    </Switch>
  );
}

export default App;
