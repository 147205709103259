import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from '@material-ui/core/TableRow';
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentProduct } from '../../store/actions/index';
// import { categories, subcategories } from '../../categories';
import config from '../../config';

const { Option } = Select;

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    // border: 'none',
  },
});

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "category", label: "ISO\u00a0Code", minWidth: 101 },
  { id: "subcategory", label: "ISO\u00a0Code", minWidth: 102 },
  { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
];

function createData(name, code) {
  // const density = population / size;
  return { name, code };
}

const response = [
  {
    id: 1,
    name: "Bob",
  },
  {
    id: 2,
    name: "Rob",
  },
  {
    id: 3,
    name: "John",
  }
];

const ProductTable = ({ headings, data, period, setPage, setInput, setSelectMonthFilter }) => {

  const { category } = useSelector(state => state.client);
  const [data1, setData1] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage1] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('ASC');
  const [SearchInput, setSearchInput] = useState('');
  const [filteredProduct, setFilteredProduct] = useState('');
  const [ProductsData, setProductsData] = useState();
  const [FilterData, setFilterData] = useState();
  // const [selectMonthFilter, setSelectMonthFilter] = React.useState("");
  // console.log(selectMonthFilter, 'selectMonthFilter');
  const [nameFilter, setNameFilter] = React.useState("");
  // const [output1, setOutput1] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();

  const rows = data.map(item =>
    createData(item.name, item.code, item.price),
  );

  const setCampaign = (campaign) => {
    localStorage.setItem("producrid", campaign._id)
    dispatch(setCurrentProduct(campaign));
    setPage('product-coupon-details');
  };

  const handleChangePage = async (event, newPage) => {
    // FilteredProduct();
    // console.log(newPage,"newPage");
    // setPage1(newPage);
    // const client = JSON.parse(localStorage.getItem('client'));
    // await fetch(`${config.apiUrl}/products?page=${newPage + 1}&count=10&categorysearch&client_id=${client._id}`, {
    //   method: 'GET',
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // }).then((res) => res.json())
    // .then((json) => {
    //   // setPro(json.products)
    //   // setFilterData(json.products)
    //   setData1(json.products)
    //   setTotal(json.total)
    // });;
    // setPage(event+1)

    const client = JSON.parse(localStorage.getItem('client'));
    // setPage1(0)
    fetch(`${config.apiUrl}/products?page=${newPage + 1}&count=10&category=${FilterData ? FilterData : ''}&search=${SearchInput}&client_id=${client._id}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json())
      .then((json) => {
        // setPro(json.products)
        // setFilterData(json.products)
        setPage1(newPage);
        setData1(json.products)
        setTotal(json.total)
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage1(0);
  };

  function handleChange(value) {
    setSelectMonthFilter(value);
  }
  // useEffect(() => {
  //   setOutput1([])
  //   data.filter(val => {
  //     console.log(val, 'vallll');

  //     if (val.name.toLowerCase().includes(selectMonthFilter.toLowerCase())) {
  //       setOutput1(output => [...output, val])
  //     }
  //   })
  // }, [selectMonthFilter])

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      // setdata(sorted);
      data = [sorted]
      setOrder("DSC")
    }
    if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      // setdata(sorted);
      data = [sorted]
      setOrder("ASC")
    }
  }
  const changeCat = (p) => { }

  const getMonthValue = (dateTime) =>
    dateTime.toLowerCase().split("t")[0].split("-")[1];

  const SeearchProduct = () => {
    const client = JSON.parse(localStorage.getItem('client'));
    setPage1(0)
    fetch(`${config.apiUrl}/products?page=1&count=10&search=${SearchInput}&client_id=${client._id}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json())
      .then((json) => {
        // setPro(json.products)
        // setFilterData(json.products)
        setData1(json.products)
        setTotal(json.total)
      });
  }

  const FilteredProduct = (e) => {
    setFilterData(e)
    const client = JSON.parse(localStorage.getItem('client'));
    setPage1(0)
    fetch(`${config.apiUrl}/products?page=1&count=10&category=${e}&search=${SearchInput}&client_id=${client._id}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

      },
    }).then((res) => res.json())
      .then((json) => {
        // setPro(json.products)
        // setFilterData(json.products)
        setData1(json.products)
        setTotal(json.total)
      });
  }

  useEffect(() => {
    // setData1(data)
    SeearchProduct()
  }, [data])

  const token = localStorage.getItem('token');

  const ResetProducts = async () => {
    setSearchInput('')
    const client = JSON.parse(localStorage.getItem('client'));
    fetch(`${config.apiUrl}/products?page=1&count=10&search&client_id=${client._id}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json())
      .then((json) => {
        // setPro(json.products)
        // setFilterData(json.products)
        // setData1(json.products)
        setProductsData(json.products);
        setData1(json.products);
        setTotal(json.total);
      });
  }

  return (
    <>
      <div className="search mb-2">
        <div className='row'>
          <div className='col-lg-4 col-12 products_select mb-lg-0 mb-3'>
            <select className="mr-lg-2 mr-0 form-control products_select_box"
              // onChange={(e) => changeCat(e.currentTarget.value)}
              style={{ height: '38px' }}
              onChange={(e) => FilteredProduct(e.currentTarget.value)}
            >
              <option disabled>Select Category..</option>
              <option value="">All</option>
              {
                category.length !== 0 && category.map((cat) =>
                  <option value={cat.category}>
                    {cat.category}
                  </option>
                )
              }
              {/* {categories && categories.map((item) =>
            <>
              <option value={item.name}>
                {item.name}
              </option>
            </>
          )} */}
            </select>
          </div>
          <div className='col-lg-5 col-12 pr-lg-0 pr-auto'>
            <div className="searchFrom products_search_bar w-100">
              <input type="text" value={SearchInput}
                style={{ height: '38px' }}
                onChange={e => setSearchInput(e.target.value)}
                id="kt_filter_search" className="input_div" placeholder="Search.." />
              {
                SearchInput ? <SearchOutlined onClick={e => SeearchProduct()} /> :
                  <SearchOutlined />
              }
            </div>
          </div>
          <div className='col-lg-3 col-12 reset_button pl-0' style={{ textAlign: 'end' }}>
            <Button
              style={{ height: '38px' }}
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={e => ResetProducts()}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
      {/* <div>
      {data1.filter(person => person.name === "Rob").map(filteredPerson => (
        <li>
          {filteredPerson.name}
        </li>
      ))}
    </div> */}
      {/* <Select
        // mode="multiple"
        style={{ width: '100%' }}
        placeholder="Select..."
        onChange={handleChange}
        optionLabelProp="label"
      >
        {data && data.map((item) =>
          <Option key={item.name}>
            {item.name}
          </Option>
        )}
      </Select> */}
      {/* <div>
        <ul>
          {data1
            .filter(({ birthdate, first_name, last_name }) =>
              selectMonthFilter || nameFilter
                ? getMonthValue(birthdate) === selectMonthFilter ||
                  first_name
                    .concat(last_name)
                    .toLowerCase()
                    .includes(nameFilter.toLowerCase())
                : true
            )
            .map((el) => (
              console.log(el, 'elelelel'),
              <li key={el.id}>{JSON.stringify(el)}</li>
            ))}
        </ul>
      </div> */}
      {
        data1 == "" ?
          <TableContainer className={classes.container}>
            <Table style={{ minWidth: '750px' }}>
              <TableHead>
                <TableRow>
                  {headings.map((head, index) => <StyledTableCell onClick={() => sorting('name')} key={index} align="left">{head}</StyledTableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
              </TableBody>
            </Table>
            <div className="text-center">
              No data found...
            </div>
          </TableContainer>
          :
          <>
            <TableContainer className={classes.container}>
              <Table style={{ minWidth: '750px' }}>
                <TableHead>
                  <TableRow>
                    {headings.map((head, index) => <StyledTableCell onClick={() => sorting('name')} key={index} align="left">{head}</StyledTableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data1.map(row => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                        <StyledTableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setCampaign(row)}
                            className={classes.button}
                            // startIcon={<SearchIcon />}
                            startIcon={<EyeOutlined />}
                          >
                            View
                          </Button>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                  
                </TableBody>
                
              </Table>
              <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
            </TableContainer>
          </>
      }
      {/* <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headings.map((head, index) => <StyledTableCell key={index} align="left">{head}</StyledTableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((campaign) => {
            const date = (period === 'future') ? campaign.campaign_start_date : campaign.campaign_expiration_date;
            return (
              <StyledTableRow hover key={campaign._id}>
                <StyledTableCell align="left">
                  {campaign.name}
                </StyledTableCell>
                <StyledTableCell align="left">{campaign && campaign.coupons && campaign.coupons.length}</StyledTableCell>
                <StyledTableCell align="left">
                { moment(date).format("MMM Do YYYY") } <small> - ( { moment().to(campaign.campaign_expiration_date) } )</small>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setCampaign(campaign)}
                    className={classes.button}
                    startIcon={<SearchIcon />}
                  >
                    View
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            )
            })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
    rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
    component="div"
    count={data.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onChangePage={handleChangePage}
    onChangeRowsPerPage={handleChangeRowsPerPage}
  /> */}
    </>
  );
}

export default ProductTable;