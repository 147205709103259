import config from "../config";

const token = localStorage.getItem('token');

const login = async (credentials) => {
  return await fetch(`${config.apiUrl}/clientlogin`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(credentials),
  });
};

const register = async (business) => {
  return await fetch(`${config.apiUrl}/clientregister`, {
    method: 'POST',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,

    },
    body: JSON.stringify(business),
  });
};

const forgotPassword = async (email) => {
  return await fetch(`${config.apiUrl}/clientforgotpassword`, {
    method: 'POST',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,

    },
    body: JSON.stringify(email),
  });
};

const verifyResetPassword = async (verify) => {
  return await fetch(`${config.apiUrl}/verifyresetpassword`, {
    method: 'POST',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,

    },
    body: JSON.stringify(verify),
  });
};

const updateProfile = async (businessId, business) => {
  return await fetch(`${config.apiUrl}/clients/${businessId}`, {
    method: 'PATCH',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,

    },
    body: JSON.stringify(business),
  });
};

const getDashboardAnalytics = async (businessId) => {
  return await fetch(`${config.apiUrl}/clients/${businessId}/dashboard`, {
    method: 'GET',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  });
}

const getCategory = async () => {
  return await fetch(`${config.apiUrl}/category/get-category`, {
    method: 'GET',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  })
}

export default {
  forgotPassword,
  verifyResetPassword,
  getDashboardAnalytics,
  login,
  register,
  updateProfile,
  getCategory
};
