import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonIcon from '@material-ui/icons/Person';
import CampaignIcon from '@mui/icons-material/Campaign';
import DescriptionIcon from '@mui/icons-material/Description';
import InventoryIcon from '@mui/icons-material/Inventory';
import React from 'react';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setBusiness } from '../store/actions/index';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MenuList = ({ history, page, setPage }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [openMenu, setMenu] = React.useState(false);
  const notify = (type, text) => toast[type](text, {
    position: toast.POSITION.TOP_RIGHT
  });

  const handleClick = () => {
    setMenu(!openMenu);
  };

  const logout = () => {
    notify('success', 'Logout Successfully');
    dispatch(setBusiness({}));
    localStorage.clear();
    history.push('/');
  }

  return (
    <List
      component="nav"
      className={classes.root}
    >
      <ListItem
        button
        selected={page === 'dashboard'}
        onClick={_ => setPage('dashboard')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem
        button
        selected={page === 'campaigns'}
        onClick={_ => setPage('campaigns')}>
        <ListItemIcon>
          <RecordVoiceOverIcon />
        </ListItemIcon>
        <ListItemText primary="Campaigns" />
      </ListItem>
      <ListItem
        button
        selected={page === 'promotions'}
        onClick={_ => setPage('promotions')}>
        <ListItemIcon>
          <CampaignIcon />
        </ListItemIcon>
        <ListItemText primary="Promotions" />
      </ListItem>
      <ListItem
        button
        selected={page === 'ProductCampaign'}
        onClick={_ => setPage('ProductCampaign')}>
        <ListItemIcon>
          <InventoryIcon />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItem>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary="Invoice" />
        {openMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            selected={page === 'invoices'}
            onClick={_ => setPage('invoices')}>
            <ListItemIcon>
              <AvTimerIcon />
            </ListItemIcon>
            <ListItemText primary="Current" />
          </ListItem>
          {/* <ListItem
            button
            className={classes.nested}
            selected={page === 'invoice-history'}
            onClick={_ => setPage('invoice-history')}>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="History" />
          </ListItem> */}
        </List>
      </Collapse>
      <Divider />
      <ListItem
        button
        selected={page === 'profile'}
        onClick={_ => setPage('profile')}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Account" />
      </ListItem>
      <ListItem
        button
        onClick={_ => logout()}>
        <ListItemIcon>
          <LockOpenIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </List>
  );
}

export default MenuList;
