import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Select } from 'antd';
import "antd/dist/antd.css";
import AWS from 'aws-sdk';
import React, { useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import { toast } from 'react-toastify';
import config from '../../config';
import services from '../../services';

const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Step2 = ({ business, setNewBusiness }) => {

  const imagePre = business.image;

  const couponImage = require('../../assets/images/logo-placeholder.png');

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isTouched, setTouched] = useState(false);
  const [inputValue, setValue] = useState("");
  const [isValid, setValid] = useState(true);
  const [profilePic, setProfilePic] = useState();

  const [image, setImage] = useState();
  const [resImage, setResImg] = useState();
  const [campaignForm, setCampaignForm] = useState({});
  const [campaigns, setCampaigns] = useState([]);

  const [categoryList, setCategoryList] = useState([])

  const [couponPic, setCouponPic] = useState(couponImage);
  const [couponForm, setCouponForm] = useState({});

  const S3_BUCKET = config.aws_bucket_name;
  const REGION = config.aws_region_name;

  AWS.config.update({
    accessKeyId: config.aws_bucket_access_key_ID,
    secretAccessKey: config.aws_bucket_access_key
  })

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  })


  const getCategory = () => {
    services.UserServices.getCategory()
      .then(res => res.json())
      .then(res => {
        setCategoryList(res?.data?.categoryList)
      })
      .catch(err => console.log('getCategory error', err));
  }

  useEffect(() => {
    getCategory()
  }, [])

  const notify = (type, text) => toast[type](text, {
    position: toast.POSITION.TOP_RIGHT
  });


  const uploadLogo = async (e) => {
    var file = e.target.files[0];

    const params = {
      Bucket: S3_BUCKET,
      Key: file.type.split("/")[0] + "/" + "profile/" + Date.now() + file.name,
      Body: file,
      ACL: 'public-read',
    };

    myBucket.upload(params, (err, data) => {
      if (err) {
        console.log(err);
        notify('error', 'Somthing went wrong')
        return;
      }
      setCouponPic(data.Location);
      setLoading(false);
      setNewBusiness({ ...business, client_logo: data.Location });
      setResImg(data.Location)
      setProfilePic(data.Location);
      notify('success', 'Image Upload Successfully.');
      setCampaignForm({});
    });
    // const base64 = await convertBase64(file)

    // setImage(base64)
    // await services.CampaignServices.uploadImage({ type: 'products', data: base64 })
    //   .then(response => response.json())
    //   .then(response => {
    //     setCouponPic(response.data.imagePath);
    //     setLoading(false);
    //     setNewBusiness({ ...business, client_logo: response.data.imagePath });
    //     setResImg(response.data.imagePath)
    //     setProfilePic(response.data.imagePath);
    //     notify('success', 'Image Upload Successfully.');
    //     setCampaignForm({});
    //     // setNewBusiness({ ...business });

    //     // setShowCreate(false);
    //     setCampaigns(response);
    //     // getCampaigns();
    //   })
    // var reader = new FileReader();
    // reader.onloadend = function() {
    //   console.log('RESULT', reader.result)
    //   setCampaignForm(campaignForm)
    //   setImage(reader.result)
    // }
    // reader.readAsDataURL(file);
  }


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.onloadend = function () {
      //   resolve(reader.result)
      //   console.log('RESULT', reader.result)
      //   setCampaignForm(campaignForm)
      //   setImage(reader.result)

      reader.onload = () => {
        resolve(reader.result)
      };
      reader.onerror = (error) => {
        reject(error)
      }
      // }

    })
  }

  // const uploadLogo = async e => {
  //   const files = e.target.files;
  //   const data = new FormData();
  //   data.append('file', files[0]);
  //   data.append('upload_preset', 'logo_upload');

  //   setLoading(true);
  //   const res = await axios.post(config.cloudinary_upload_url, data);

  //   if (res.status !== 200) return false;

  //   setProfilePic(res.data.secure_url);
  //   setLoading(false);
  //   setNewBusiness({ ...business, client_logo: res.data.secure_url });
  // };

  const plainOptions = ['Storefront', 'E-Commerce', 'Brand / CPG'];

  function CheckBoxchange(checkedValues) {


    setNewBusiness({ ...business, businessType: checkedValues })
  }


  function isText(val) {
    return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  }

  // function isNum(val) {
  //   return /^\d+$/.test(val);
  // }
  const handleInput = (event) => {
    setValue(event.target.value);
    validate(event.target.value);
    setNewBusiness({ ...business, [event.target.id]: event.target.value })
  }
  const validate = val => {
    // return console.log(business.email, 'business.email === "email"');


    if (business.url) {
      if (isText(val)) setValid(true);
      else setValid(false);
    }
    // if (props.type === "number") {
    //   if (isNum(val)) setValid(true);
    //   else setValid(false);
    // }

  }
  return (
    <form noValidate autoComplete="off">
      <h6 className="">Perhaps we need to add a text to let user know that they can select more than one option</h6>

      <h5 className="wizard-title py-2">Business Details</h5>

      {/* <Form

        className="login-form"
        initialValues={{ remember: true }}
      // onFinish={reset}
      // disabled={validateForgotPasswordForm(email)}
      >

        <Form.Item
          name="businessType"

          rules={[
            {
              required: true,
              message: 'Please input your First Name!',
            }
          ]}
        >
          <FormControl component="fieldset">

            <Checkbox.Group options={plainOptions} defaultValue={['Apple']} onChange={CheckBoxchange} />

          </FormControl>
        </Form.Item>


        <Form.Item
          name="client_name"
          rules={[
            {
              required: true,
              message: 'Please input your Business Name!',
            }
          ]}
        >
          <Input
            value={business?.client_name}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Business Name"
          />
        </Form.Item>

        


        <Form.Item
          name="about"
          rules={[
            {
              required: true,
              message: 'Please About  your Business!',
            }
          ]}
        >
          <TextArea
            rows="4"
            defaultValue={business.about}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="About your business"
          />
        </Form.Item>


        <div className="row mb-4">
          <div className="col">
            <select
              id="category"
              className="form-control"
              defaultValue={business.category}
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })} >
              <option value="">Select a category</option>
              {
                categories.map((cat, index) => {
                  return (
                    <option key={index} value={cat.id}>{cat.name}</option>
                  )
                })
              }
            </select>
          </div>
        </div>

        <Form.Item
          name="tags"
          rules={[
            {
              required: true,
              message: 'Please input your Tags Name!',
            }
          ]}
        >
          <Input
            value={business?.tags}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Tags- ex: greek, black-owned, vegan, organic, barbeque, etc "
          />
        </Form.Item>

        <Form.Item
          name="website"
          rules={[
            {
              required: true,
              type: "url",
              message: 'Please input your website Name!',
            }
          ]}
        >

          <Input

            value={business?.website}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="https://example.com"
            pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
            required />

        </Form.Item>


      </Form> */}
      <div className="row mb-3">
        <div className="col" >
          <FormControl component="fieldset">

            <Checkbox.Group options={plainOptions}
              value={business?.businessType}
              defaultValue={['Apple']} onChange={CheckBoxchange} />

          </FormControl>


        </div>
      </div>

      {/* <RadioGroup aria-label="frequency" value={business.businessType} onChange={event => setNewBusiness({ ...business, businessType: event.target.value })}>
              <div className="row">
                <div className="col"><FormControlLabel value="storefront" control={<Radio />} label="Storefront" /></div>
                <div className="col"><FormControlLabel value="online" control={<Radio />} label="Online" /></div>
                <div className="col"><FormControlLabel value="both" control={<Radio />} label="Both" /></div>
              </div>
            </RadioGroup> */}
      <div className="row mb-4">
        <div className="col">
          <input
            className="form-control"
            required
            id="client_name"
            value={business?.client_name}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Business Name" />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <select
            id="category"
            className="form-control"
            defaultValue={business?.category}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })} >
            <option value="">Select a category</option>
            {categoryList.length === 0 && <option>Loading...</option>}
            {
              categoryList.length !== 0 && categoryList?.map((cat, index) => {
                return (
                  <option key={index} value={cat?.category}>{cat?.category}</option>
                )
              })
            }
            {/* {
              categories.map((cat, index) => {
                return (
                  <option key={index} value={cat.id}>{cat.name}</option>
                )
              })
            } */}
          </select>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <textarea
            id="about"
            required
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="About your business"
            className="form-control"
            rows="3"
            defaultValue={business.about}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <input
            className="form-control"
            required
            id="tags"
            value={business?.tags}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Tags- ex: greek, black-owned, vegan, organic, barbeque, etc " />
        </div>
      </div>
      {isTouched && !inputValue && (
        <p style={{ color: "red" }}>Please Enter Tags</p>
      )}
      <div className="row mb-3">
        <div className="col">



          <input
            className="form-control"
            required
            id="website"
            type="url"
            value={business?.website}
            onBlur={() => setTouched(true)}
            placeholder="https://example.com"
            pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
            onChange={(event) => handleInput(event)}
          // onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
          />
        </div>
      </div>
      {isTouched && !inputValue && (
        <p style={{ color: "red" }}>Please Enter Right Url</p>
      )}
      <div className="row mb-5">

        <div className="col">
          <p className="uploadlogo">
            Upload Logo
          </p>
          <input
            required
            id="logo"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={uploadLogo}
            placeholder="Upload logo" />

          <div className="preview mt-2">
            {loading ? (
              <Spinner animation="border" />
            ) : (
              business?.client_logo ? <img  src={business?.client_logo} alt="Coupon" width="150" /> :
                <img src={couponImage} alt="Coupon" width="150" />
            )}
            {/* {loading ? (
              <Spinner animation="border" />
            ) : (
              business?.client_logo ? <img src={`${config.apiUrl}` + business?.client_logo} alt="Coupon" width="150" /> :
                <img src={couponImage} alt="Coupon" width="150" />
            )} */}
          </div>
        </div>

      </div>
    </form>
  )
}

export default Step2;
