import React, { useState } from 'react';
import { Form, Input, } from 'antd';
import { useForm } from "react-hook-form";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
const Step1 = ({ business, setNewBusiness }) => {
  const [showPass, setshowPassword] = useState(false)
  const [isTouched, setTouched] = useState(false);
  const [inputValue, setValue] = useState("");
  const [isValid, setValid] = useState(true);


  




  const handleClickShowPassword = () => {
   
    setshowPassword(!showPass)
  };
  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  function isNum(val) {
    return /^\d+$/.test(val);
  }
  function isText(val) {
    return /^[a-zA-Z]+ [a-zA-Z]+$/;
  }

  const handleInput = (event) => {

    
    setValue(event.target.value);
    validate(event.target.value);
    setNewBusiness({ ...business, [event.target.id]: event.target.value })
  }
  const validate = val => {
    // return console.log(business.email, 'business.email === "email"');

    if (business.email) {
      if (emailIsValid(val)) setValid(true);
      else setValid(false);
    }
    if (business.first_name) {
      if (isText(val)) setValid(true);
      else setValid(false);
    }
    if (business.last_name) {
      if (isText(val)) setValid(true);
      else setValid(false);
    }

    // let error;
    // if (!val.value) {
    //   error = "Required";
    // } else if (!val.regex.test(val.value)) {
    //   error = `Invalid ${val.key}`;
    // }
    // return error;
  };
  return (
    <>
      <h5 className="wizard-title py-2">Credentials</h5>

      {/* <Form

        className="login-form"
        initialValues={{ remember: true }}
      // onFinish={reset}
      // disabled={validateForgotPasswordForm(email)}
      >

        <Form.Item
          name="first_name"

          rules={[
            {
              required: true,
              message: 'Please input your First Name!',
            }
          ]}
        >
          <Input
            value={business?.first_name}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder={business?.first_name}
          />
        </Form.Item>

        <Form.Item
          name="last_name"
          rules={[{ required: true, message: 'Please fill  Last Name!' }]}
        >
          <Input
            value={business?.last_name}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Last Name"
          />
        </Form.Item>

        <Form.Item
          name="email"

          rules={[{ required: true, type: "email", message: 'Please input your Email!' }]}
        >
          <Input
            value={business?.email}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}

            placeholder="Email"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please input your Password!' },
          ]}
        >
          <Input.Password
            type="password"
            value={business?.password}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Password"
          />
        </Form.Item>

      </Form> */}

      <div className="row mb-4">
        <div className="col">

          <input
            className="form-control"
            id="first_name"
            type="text"
            value={business?.first_name}
            onBlur={() => setTouched(true)}
            onChange={(event) => handleInput(event)}
            placeholder="First Name"
          />
        </div>

      </div>
      {isTouched && !inputValue && (
        <p style={{ color: "red" }}>Please Enter First-Name</p>
      )}
      <div className="row mb-4">
        <div className="col">
          <input
            className="form-control"
            type="text"
            id="last_name"
            value={business?.last_name}
            onChange={(event) => handleInput(event)}
            onBlur={() => setTouched(true)}

            // onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Last Name"
            required={true}
          />
        </div>

      </div>
      {isTouched && !inputValue && (
        <p style={{ color: "red" }}>Please Enter Last-Name</p>
      )}
      <div className="row mb-4">
        <div className="col">
          <input
            className="form-control"
            id="email"
            value={business?.email}
            onBlur={() => setTouched(true)}

            onChange={(event) => handleInput(event)}
            // onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Email" />
        </div>


      </div>
      {isTouched && !isValid && (
        <p style={{ color: "red" }}>Please enter valid value.</p>
      )}
      <div className="row mb-4">
        <div className="col">
          <div className="formWrapper">
            <input
              className="form-control"
              id="password"
              type={showPass ? 'text' : 'password'}
              onBlur={() => setTouched(true)}

              placeholder="Password"
              value={business?.password}
              onChange={(event) => handleInput(event)}

            // onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            />
            <IconButton
              aria-label="Toggle password visibility"
              onClick={handleClickShowPassword}
            // onMouseDown={this.handleMouseDownPassword}
            >
              {showPass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </div>
          {isTouched && !inputValue && (
            <p style={{ color: "red" }}>Please enter Password</p>
          )}
        </div>
      </div>
    </>
  )
}

export default Step1;
