import { TextField } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import HomeIcon from '@material-ui/icons/Home';
import AWS from 'aws-sdk';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ChartSkeleton from '../../components/ChartSkeleton';
import config from '../../config';
import services from '../../services/index';

const couponImage = require('../../assets/images/logo-placeholder.png');

toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  textFieldLeft: {
    paddingLeft: 15,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  },
  textFieldRight: {
    paddingLeft: 15
  }
}));

export const customStylesOfSelect = {
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: "16px",
      fontWeight: 400,
      color: "#495057",
    }
  },

  control: (provided, state) => ({
    ...provided,
    minHeight: '48px',
    height: '100%',
    boxShadow: "none",
    alignContent: "center",
    fontSize: "12px",
    borderColor: state.isFocused ? "#80bdff" : provided.borderColor,
    boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : provided.borderColor,
    "&:hover": {
      borderColor: state.isFocused ? "#80bdff" : provided.borderColor,
    },
  }),

  option: (provided, state) => ({
    ...provided,
    padding: "3px 10px",
    color: state.isSelected ? "#fff" : state.isFocused ? "#fff" : provided.color,
    backgroundColor: state.isSelected
      ? "#1e90ff"
      : state.isFocused
        ? "#1e90ff"
        : provided.backgroundColor,
  }),

  multiValue: (styles) => {
    return {
      ...styles,
      fontSize: "14px",
    };
  },

  dropdownIndicator: base => ({
    ...base,
    color: "#495057" // Custom colour
  })
};

const Profile = () => {
  const S3_BUCKET = config.aws_bucket_name;
  const REGION = config.aws_region_name;

  AWS.config.update({
    accessKeyId: config.aws_bucket_access_key_ID,
    secretAccessKey: config.aws_bucket_access_key
  })

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  })

  const classes = useStyles();
  const data = useSelector((data) => data.client)
  let [business, setBusiness] = useState(JSON.parse(localStorage.getItem('client')));

  // const category = categories.filter(cat => business?.category?.split(',').includes(cat.id.toString()));

  const category = data?.category.filter((cat) => business?.category?.split(',').includes(cat?.category))
  const finalCategory = category.map((cat) => ({ label: cat?.category, value: cat?.category }))

  // const category = categories.find(cat => cat.id == parseInt(business.category)) || { name: '' };

  if (business != undefined && business.hours_of_operation == undefined) {
    business.hours_of_operation = {
      "sunday": {
        "enabled": true,
        "open": "00:00",
        "close": "00:00"
      },
      "monday": {
        "enabled": true,
        "open": "00:00",
        "close": "00:00"
      },
      "tuesday": {
        "enabled": true,
        "open": "00:00",
        "close": "00:00"
      },
      "wednesday": {
        "enabled": true,
        "open": "00:00",
        "close": "00:00"
      },
      "thursday": {
        "enabled": true,
        "open": "00:00",
        "close": "00:00"
      },
      "friday": {
        "enabled": true,
        "open": "00:00",
        "close": "00:00"
      },
      "saturday": {
        "enabled": true,
        "open": "00:00",
        "close": "00:00"
      }
    }
  }

  const [editProfile, setEditProfile] = useState({ hours_of_operation: (business != undefined && business.hours_of_operation) ? business.hours_of_operation : {}, category: finalCategory });
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showEditContact, setShowEditContact] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [showEditInfo, setShowEditInfo] = useState(false);
  const [showEditAbout, setShowEditAbout] = useState(false);
  const [showEditTags, setShowEditTags] = useState(false);
  const [showEditHours, setShowEditHours] = useState(false);
  const [showDisableProfile, setShowDisableProfile] = useState(false);
  const { states } = config;
  const notify = (type, text) => toast[type](text, {
    position: toast.POSITION.TOP_RIGHT
  });
  const [LogoPic, setLogoPic] = useState(business.client_logo);

  const [BannerPic, setBannerPic] = useState(business.banner);

  let bannerHeight = '';
  let bannerWidth = '';

  const [uploadedLogo, setUploadedLogo] = useState();
  const [logoLoader, setLogoLoader] = useState(false);

  const [fileUploadType, setFileUploadType] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [fileSizeError, setFileSizeError] = useState(false);
  const [bannerLoader, setBannerLoader] = useState(false);

  const enableDay = (event) => {
    setBusiness({
      ...business,
      hours_of_operation: {
        ...business.hours_of_operation,
        [event.target.id]: {
          ...business.hours_of_operation[event.target.id],
          enabled: !business.hours_of_operation[event.target.id].enabled
        }
      }
    });
    setEditProfile({
      ...editProfile,
      hours_of_operation: {
        ...editProfile.hours_of_operation,
        [event.target.id]: {
          ...editProfile.hours_of_operation[event.target.id],
          enabled: !editProfile.hours_of_operation[event.target.id].enabled
        }
      }
    });
  };

  const setHours = (day, event) => {
    setBusiness({
      ...business,
      hours_of_operation: {
        ...business.hours_of_operation,
        [day]: {
          ...business.hours_of_operation[day],
          [event.target.id]: event.target.value
        }
      }
    });
    setEditProfile({
      ...editProfile,
      hours_of_operation: {
        ...editProfile.hours_of_operation,
        [day]: {
          ...editProfile.hours_of_operation[day],
          [event.target.id]: event.target.value
        }
      }
    });
  };

  const uploadLogo = async (e) => {

    // let file = e.target.files[0];

    setUploadedLogo(e.target.files[0])

    // const base64 = await convertBase64(file)

    // setImage(base64)
    // await services.CampaignServices.uploadImage({ type: 'products', data: base64 })
    //   .then(response => response.json())
    //   .then(response => {
    //     setLogoPic(response.data.imagePath);
    //     setLoading(false);

    //     // setCouponForm({ ...couponForm, image: response.data.imagePath });
    //     // setResImg(response.data.imagePath)
    //     notify('success', 'Image Upload Successfully.');
    //     // setCampaignForm({});

    //     // setShowCreate(false);
    //     // getCampaigns();
    //   })

  }
  const submitLogo = (modal) => {

    setLogoLoader(true);

    const params = {
      Bucket: S3_BUCKET,
      Key: uploadedLogo.type.split("/")[0] + "/" + "profile/" + Date.now() + uploadedLogo.name,
      Body: uploadedLogo,
      ACL: 'public-read',
    };

    myBucket.upload(params, (err, data) => {
      if (err) {
        console.log(err);
        notify('error', 'Somthing went wrong')
        setLogoLoader(false);
        return;
      }

      const payload = {
        client_logo: data.Location
      }

      services.UserServices.updateProfile(business._id, payload)
        .then(res => res.json())
        .then(profile => {

          if (profile.err) {
            setLogoLoader(false);
            return false
          };

          notify('success', 'Updated successfully.');
          // setEditProfile({ hours_of_operation: profile.data.client_data.hours_of_operation });
          setBusiness(profile.data.client_data);
          setLogoPic(profile.data.client_data.client_logo)
          localStorage.setItem('client', JSON.stringify(profile.data.client_data));
          setLogoLoader(false);
          setUploadedLogo();
          // modal(false);
        })
        .catch(e => {
          setLogoLoader(false);
          console.log(e)
        });
    });

    // const payload = {
    //   client_logo: LogoPic
    // }
    // services.UserServices.updateProfile(business._id, payload)
    //   .then(res => res.json())
    //   .then(profile => {

    //     if (profile.err) return false;
    //     notify('success', 'Updated successfully.');
    //     // setEditProfile({ hours_of_operation: profile.data.client_data.hours_of_operation });
    //     setBusiness(profile.data.client_data);
    //     setLogoPic(profile.data.client_data.client_logo)
    //     localStorage.setItem('client', JSON.stringify(profile.data.client_data));
    //     modal(false);
    //   })
    //   .catch(e => console.log(e));
  };

  const uploadbanner = async (e) => {

    let file = e.target.files[0];


    if (file && file.type.includes("image")) {
      setFileSizeError(false)
      setFileUploadType(file.type);
      // const base64 = await convertBase64(file);
      setUploadedFile(file);
    }

    if (file && file.type.includes("video")) {
      if (file.size < (51 * 1024 * 1024)) {

        // doTranscode(file, file.name)
        setFileSizeError(false)
        setFileUploadType(file.type);
        // const base64 = await convertBase64(file);
        setUploadedFile(file);
      } else {
        setFileSizeError(true);
        setUploadedFile();
      }
    }
  }

  const submitBanner = async (modal) => {
    setBannerLoader(true);

    const params = {
      Bucket: S3_BUCKET,
      Key: uploadedFile.type.split("/")[0] + "/" + "profile/" + Date.now() + "__" + bannerWidth + "-" + bannerHeight + "__" + uploadedFile.name,
      Body: uploadedFile,
      ACL: 'public-read',
    };

    myBucket.upload(params, (err, data) => {
      if (err) {
        console.log(err);
        notify('error', 'Somthing went wrong')
        setBannerLoader(false);
        return;
      }

      const payload = {
        banner: data.Location
      }

      services.UserServices.updateProfile(business._id, payload)
        .then(res => res.json())
        .then(profile => {
          if (profile.err) {
            notify('error', profile.message)
            setBannerLoader(false);
            return false
          };
          // setEditProfile({ hours_of_operation: profile.data.client_data.hours_of_operation });
          notify('success', 'Updated successfully.');
          setBusiness(profile.data.client_data);
          setBannerPic(profile.data.client_data.banner)
          localStorage.setItem('client', JSON.stringify(profile.data.client_data));
          setUploadedFile();
          setFileSizeError(false);
          setFileUploadType();
          modal(false);
          setBannerLoader(false);
        })
        .catch((err) => {
          console.log("Update errro", err)
          // notify('error', 'Something went to wrong.')
          setBannerLoader(false);
        });
    });
  };

  const handleEdit = (modal) => {
    if (editProfile.zip != undefined && editProfile.zip != null) {
      editProfile.zip_code = editProfile.zip;
    }
    editProfile.location = {};
    editProfile.category = (editProfile.category.map((cat) => cat.value))?.toString()

    services.UserServices.updateProfile(business._id, editProfile)
      .then(res => res.json())
      .then(profile => {
        if (profile.err) return false;
        notify('success', 'Updated successfully.');

        // const category = categories.filter(cat => profile.data.client_data?.category?.split(',').includes(cat.id.toString()));
        // const finalCategory = category.map((cat) => ({ label: cat.name, value: cat.id }))

        const category = data?.category.filter((cat) => profile.data.client_data?.category?.split(',').includes(cat?.category))
        const finalCategory = category.map((cat) => ({ label: cat?.category, value: cat?.category }))

        setEditProfile({ hours_of_operation: profile.data.client_data.hours_of_operation, category: finalCategory });
        setBusiness(profile.data.client_data);
        localStorage.removeItem('client');
        setTimeout(() => {
          localStorage.setItem('client', JSON.stringify(profile.data.client_data));
        }, 100);
        modal(false);
      })
      .catch(e => console.log(e));
  };

  const handleClick = (event) => {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  };

  const setOpenOrClose = (day) => {
    if (day.enabled) {
      return `${moment(day.open, 'H:mm').format('h:mm a')} - ${moment(day.close, 'H:mm').format('h:mm a')}`;
    }
    return "Closed";
  }

  const handleMetadataLoaded = (event) => {
    const { videoWidth, videoHeight } = event.target;
    bannerHeight = videoHeight;
    bannerWidth = videoWidth
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={window.innerWidth >= 992 ? 'container' : ''}>
        <div className="row">
          <div className="col pl-md-0 pl-auto pr-md-0 pr-auto">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/" onClick={handleClick}>
                <HomeIcon />
              </Link>
              <Typography color="textPrimary">Profile</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col pl-md-0 pl-auto pr-md-0 pr-auto">
            {business ? (
              <Card>
                <CardHeader
                  title="Account Info"
                  subheader="Manage your account settings" />
                <CardContent>
                  <div className="row">
                    <div className="col-lg-8 col-12">
                      <div className="row">
                        <div className="col-12 col-xl-6">
                          <h6><u>Business Name</u></h6>
                          <h6 className='pt-0 account_info'>{business.client_name}</h6>
                        </div>
                        <div className="col-12 col-xl-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <h6><u>Category</u></h6>
                            <h6 className='pt-0 account_info'>{category?.map((cat) => cat?.category).toString()}</h6>
                            {/* <h6>{category.map((cat) => cat.name).toString()}</h6> */}
                            {/* <h6>{business?.category}</h6> */}
                          </div>
                          <Tooltip title="Edit your name and category">
                            <IconButton style={{alignSelf : "flex-start"}} aria-label="edit your account settings" onClick={() => setShowEditProfile(true)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-xl-6">
                          <h6><u>Contact Person</u></h6>
                          <h6 className='pt-0 account_info'>{business.first_name} {business.last_name}</h6>
                        </div>
                        <div className="col-12 col-xl-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <h6><u>Phone</u></h6>
                            <h6 className='pt-0 account_info'>{business.phone}</h6>
                          </div>
                          <Tooltip title="Edit your contact person and phone number">
                            <IconButton style={{alignSelf : "flex-start"}} aria-label="edit your account settings" onClick={() => setShowEditContact(true)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <h6><u>Address</u></h6>
                            <h6 className='pt-0 account_info'>{business.address}</h6>
                            <h6 className='pt-0 account_info'>{business.city}, {business.state} {business.zip}</h6>
                          </div>
                          <Tooltip title="Edit your contact email and website">
                            <IconButton style={{alignSelf : "flex-start"}} aria-label="edit your account settings" onClick={() => setShowEditAddress(true)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-xl-6">
                          <h6><u>Email</u></h6>
                          <h6 className='pt-0 account_info'>{business.email}</h6>
                        </div>
                        <div className="col-12 col-xl-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <h6><u>Website</u></h6>
                            <h6 className='pt-0 account_info'>{business.website}</h6>
                          </div>
                          <Tooltip title="Edit your contact email and website">
                            <IconButton style={{alignSelf : "flex-start"}} aria-label="edit your account settings" onClick={() => setShowEditInfo(true)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <h6><u>About</u></h6>
                            <h6 className='pt-0 account_info'>{business.about}</h6>
                          </div>
                          <Tooltip title="Edit your business about">
                            <IconButton style={{alignSelf : "flex-start"}} aria-label="edit your account settings" onClick={() => setShowEditAbout(true)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <h6><u>Tags</u></h6>
                            <h6 className='pt-0 account_info'>{business.tags}</h6>
                          </div>
                          <Tooltip title="Edit your business tags">
                            <IconButton style={{alignSelf : "flex-start"}} aria-label="edit your account settings" onClick={() => setShowEditTags(true)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <h6><u>Hours of Operation</u></h6>
                            <div>Sunday: {setOpenOrClose(business.hours_of_operation.sunday)}</div>
                            <div>Monday: {setOpenOrClose(business.hours_of_operation.monday)}</div>
                            <div>Tuesday: {setOpenOrClose(business.hours_of_operation.tuesday)}</div>
                            <div>Wednesday: {setOpenOrClose(business.hours_of_operation.wednesday)}</div>
                            <div>Thursday: {setOpenOrClose(business.hours_of_operation.thursday)}</div>
                            <div>Friday: {setOpenOrClose(business.hours_of_operation.friday)}</div>
                            <div>Saturday: {setOpenOrClose(business.hours_of_operation.saturday)}</div>
                          </div>
                          <Tooltip title="Edit your hours of operation">
                            <IconButton style={{alignSelf : "flex-start"}} aria-label="edit your account settings" onClick={() => setShowEditHours(true)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12 mt-3 mt-lg-0">
                      <h6><u>Logo</u></h6>
                      {
                        logoLoader ?
                          <Spinner animation="border" /> :
                          (uploadedLogo ?
                            <img src={URL.createObjectURL(uploadedLogo)} alt="logo" width="150" /> :
                            LogoPic ?
                              <img src={`${config.awsBucketUrl}${LogoPic}`} alt="logo" width="150" /> :
                              <img src={couponImage} alt="logo" width="150" />)
                      }
                      {/* {loading ? (
                            <Spinner animation="border" />
                          ) : (LogoPic ? <img src={`${config.apiUrl}` + LogoPic} alt="logo" width="150" /> :
                            <img src={couponImage} alt="logo" width="150" />
                          )} */}
                      <div className="mt-2">
                        <input
                          required
                          style={{ height: 48, padding: '9px' }}
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          name="profile_logo"
                          className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                          onChange={(event) => uploadLogo(event)} />
                      </div>
                      <button className="btn btn-primary mt-2 w-auto"
                        disabled={!uploadedLogo || logoLoader}
                        onClick={(e) => submitLogo(e)}>Update</button>
                      {/* >Save</button> */}
                      <h6><u>Promotional Banner (50 MB max.)</u></h6>
                      {bannerLoader
                        ?
                        <div className='d-flex justify-content-center align-items-center' style={{ height: '100px', width: "100px" }}>
                          <Spinner animation="border" />
                        </div>
                        :
                        uploadedFile ?
                          (
                            fileUploadType?.includes("video") ?
                              <video src={URL.createObjectURL(uploadedFile)} width="150" height="100" controls onLoadedMetadata={(e) => handleMetadataLoaded(e)}></video> :
                              <img src={URL.createObjectURL(uploadedFile)} alt="Coupon" width="150" height="100" />
                          )
                          :
                          (business?.banner ?
                            (business?.banner?.includes("video") ? <video src={`${config.awsBucketUrl}${business?.banner}`} width="150" height="100" controls></video> :
                              <img src={`${config.awsBucketUrl}${business?.banner}`} alt="Coupon" width="150" height="100" />)
                            :
                            <img src={couponImage} alt="prev" width="150" height="100" />)
                      }
                      {fileSizeError && <p className='text-danger mt-1'>* Max Upload is 50mb</p>}
                      <div className="mt-2">
                        {/* <Button block variant="primary" size="sm" onClick={uploadBanner}>Upload a Promotional Banner</Button> */}
                        <input
                          type="file"
                          accept="image/png, image/gif, image/jpeg, video/*"
                          style={{ height: 48, padding: '9px' }}
                          required
                          name="profile_banner"
                          className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                          onChange={(event) => uploadbanner(event)} />

                      </div>
                      <button className="btn btn-primary mt-2 w-auto" disabled={!uploadedFile || bannerLoader}
                        onClick={(e) => submitBanner(e)}>update</button>
                      {/* >Save</button> */}
                    </div>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <ChartSkeleton />
            )}
          </div>
        </div>
      </div>
      <Modal show={showEditProfile} onHide={() => setShowEditProfile(false)} size="lg" centered className='edit_businessName_category_modal'>
        <Modal.Header closeButton>
          <Modal.Title>UPDATE BUSINESS NAME AND CATEGORY</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form>
                <Form.Row className="mt-3">
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>BUSINESS NAME</Form.Label>
                    <Form.Control
                      style={{ height: 48 }}
                      className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                      value={editProfile.client_name || business.client_name}
                      onChange={e => setEditProfile({ ...editProfile, client_name: e.target.value })} />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mt-3">
                  <Form.Group as={Col} xs={12} controlId="category">
                    <Form.Label>CATEGORY</Form.Label>
                    {/* <Form.Control as="select"
                      defaultValue={business.category}
                      onChange={event => {
                        console.log("event.target.value", event.target.value)
                        setEditProfile({ ...editProfile, category: parseInt(event.target.value) })
                      }}
                      >
                      <option>Choose a category</option>
                      {categories.map((cat, index) => <option key={index} value={cat.id}>{cat.name}</option>)}
                    </Form.Control> */}

                    {/* <Select
                      value={editProfile.category}
                      placeholder="Choose a category"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={categories?.map((category, i) => (
                        { value: category.id, label: category.name }
                        ))}
                        isMulti
                        styles={customStylesOfSelect}
                        onChange={(e) => setEditProfile({ ...editProfile, category: e })}
                      /> */}
                    <Select
                      style={{ height: 48 }}
                      value={editProfile.category}
                      placeholder="Choose a category"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={data?.category?.map((cat, i) => (
                        { value: cat?.category, label: cat?.category }
                      ))}
                      isMulti
                      styles={customStylesOfSelect}
                      onChange={(e) => setEditProfile({ ...editProfile, category: e })}
                    />
                  </Form.Group>
                </Form.Row>

              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleEdit(setShowEditProfile)}>Update</Button>
          <Button variant="light" onClick={() => setShowEditProfile(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditContact} onHide={() => setShowEditContact(false)} size="lg" centered className='edit_businessContact_modal'>
        <Modal.Header closeButton>
          <Modal.Title>UPDATE BUSINESS CONTACT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>FIRST NAME</label>
            <input
              className="form-control"
              style={{ height: '48px' }}
              type="text"
              value={editProfile.first_name || business.first_name} // Prop: The zip input data
              onChange={e => setEditProfile({ ...editProfile, first_name: e.target.value })} // Prop: Puts data into state
            />
          </div>
          <div className="form-group">
            <label>LAST NAME</label>
            <input
              className="form-control"
              style={{ height: '48px' }}
              type="text"
              value={editProfile.last_name || business.last_name} // Prop: The zip input data
              onChange={e => setEditProfile({ ...editProfile, last_name: e.target.value })} // Prop: Puts data into state
            />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              className="form-control"
              style={{ height: '48px' }}
              type="text"
              value={editProfile.phone || business.phone} // Prop: The zip input data
              onChange={e => setEditProfile({ ...editProfile, phone: e.target.value })} // Prop: Puts data into state
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleEdit(setShowEditContact)}>Update</Button>
          <Button variant="light" onClick={() => setShowEditContact(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditAddress} onHide={() => setShowEditAddress(false)} size="lg" centered className='edit_businessAddress_modal'> 
        <Modal.Header closeButton>
          <Modal.Title>UPDATE BUSINESS ADDRESS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Address</label>
            <input
              className="form-control"
              style={{ height: '48px' }}
              type="text"
              value={editProfile.address || business.address} // Prop: The address input data
              onChange={e => setEditProfile({ ...editProfile, address: e.target.value })} // Prop: Puts data into state
            />
          </div>
          <div className="form-group">
            <label>City</label>
            <input
              className="form-control"
              style={{ height: '48px' }}
              type="text"
              value={editProfile.city || business.city}
              onChange={e => setEditProfile({ ...editProfile, city: e.target.value })} // Prop: Puts data into state
            />
          </div>
          <div className="form-group">
            <label>State</label>
            <select
              name="state"
              className="form-control"
              style={{ height: '48px' }}
              defaultValue={business.state}
              onChange={e => setEditProfile({ ...editProfile, state: e.target.value })} >
              <option value="">Select a state</option>
              {
                states.map((state, index) => {
                  return (
                    <option key={index} value={state}>{state}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="form-group">
            <label>Zip</label>
            <input
              className="form-control"
              style={{ height: '48px' }}
              type="text"
              value={editProfile.zip || business.zip} // Prop: The zip input data
              onChange={e => setEditProfile({ ...editProfile, zip: e.target.value })} // Prop: Puts data into state
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleEdit(setShowEditAddress)}>Update</Button>
          <Button variant="light" onClick={() => setShowEditAddress(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditInfo} onHide={() => setShowEditInfo(false)} size="lg" centered className='edit_contactIfo_modal'>
        <Modal.Header closeButton>
          <Modal.Title>UPDATE CONTACT INFO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Email</label>
            <input
              className="form-control"
              style={{ height: '48px' }}
              type="text"
              value={editProfile.email || business.email} // Prop: The zip input data
              onChange={e => setEditProfile({ ...editProfile, email: e.target.value })} // Prop: Puts data into state
            />
          </div>
          <div className="form-group">
            <label>Website</label>
            <input
              className="form-control"
              style={{ height: '48px' }}
              type="text"
              value={editProfile.website || business.website} // Prop: The zip input data
              onChange={e => setEditProfile({ ...editProfile, website: e.target.value })} // Prop: Puts data into state
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleEdit(setShowEditInfo)}>Update</Button>
          <Button variant="light" onClick={() => setShowEditInfo(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditAbout} onHide={() => setShowEditAbout(false)} size="lg" centered className='edit_businessAbout_modal'>
        <Modal.Header closeButton>
          <Modal.Title>UPDATE BUSINESS ABOUT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>About</label>
            <textarea
              name="about"
              onChange={e => setEditProfile({ ...editProfile, about: e.target.value })}
              className="form-control"
              rows="10"
              defaultValue={editProfile.about || business.about}></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleEdit(setShowEditAbout)}>Update</Button>
          <Button variant="light" onClick={() => setShowEditAbout(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditTags} onHide={() => setShowEditAbout(false)} size="lg" centered className='edit_businessTag_modal'>
        <Modal.Header>
          <Modal.Title>UPDATE BUSINESS TAGS</Modal.Title>
          <Modal.Title onClick={() => setShowEditTags(false)} style={{ cursor: 'pointer' }}>x</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>TAGS</label>
            <input
              className="form-control"
              style={{ height: '48px' }}
              type="text"
              value={editProfile.tags || business.tags}
              onChange={e => setEditProfile({ ...editProfile, tags: e.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleEdit(setShowEditTags)}>Update</Button>
          <Button variant="light" onClick={() => setShowEditTags(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditHours} onHide={() => setShowEditHours(false)} size="lg" centered className='edit_businessHours_modal'>
        <Modal.Header closeButton>
          <Modal.Title>UPDATE BUSINESS HOURS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Hours of Operation</label>
          <FormControl component="fieldset" className={classes.formControl}>
            <div className="row mb-3">
              <div className="col-lg-4 col-12 text-left">
                <FormControlLabel
                  control={<Checkbox checked={business.hours_of_operation.sunday.enabled}
                    onChange={enableDay}
                    id="sunday" />}
                  label="Sunday"
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="open"
                  type="time"
                  disabled={!business.hours_of_operation.sunday.enabled}
                  defaultValue={business.hours_of_operation.sunday.open || "00:00"}
                  onChange={(event) => setHours('sunday', event)}
                  className={classes.textFieldRight}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="close"
                  type="time"
                  disabled={!business.hours_of_operation.sunday.enabled}
                  defaultValue={business.hours_of_operation.sunday.close || "23:59"}
                  className={classes.textFieldLeft}
                  sx={classes.leftTextField}
                  onChange={(event) => setHours('sunday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-4 col-12 text-left">
                <FormControlLabel
                  control={<Checkbox checked={business.hours_of_operation.monday.enabled}
                    onChange={enableDay}
                    id="monday" />}
                  label="Monday"
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="open"
                  type="time"
                  disabled={!business.hours_of_operation.monday.enabled}
                  defaultValue={business.hours_of_operation.monday.open || "00:00"}
                  className={classes.textFieldRight}
                  onChange={(event) => setHours('monday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="close"
                  type="time"
                  disabled={!business.hours_of_operation.monday.enabled}
                  defaultValue={business.hours_of_operation.monday.close || "23:59"}
                  className={classes.textFieldLeft}
                  onChange={(event) => setHours('monday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-4 col-12 text-left">
                <FormControlLabel
                  control={<Checkbox checked={business.hours_of_operation.tuesday.enabled}
                    onChange={enableDay}
                    id="tuesday" />}
                  label="Tuesday"
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="open"
                  type="time"
                  disabled={!business.hours_of_operation.tuesday.enabled}
                  defaultValue={business.hours_of_operation.tuesday.open || "00:00"}
                  className={classes.textFieldRight}
                  onChange={(event) => setHours('tuesday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="close"
                  type="time"
                  disabled={!business.hours_of_operation.tuesday.enabled}
                  defaultValue={business.hours_of_operation.tuesday.close || "23:59"}
                  className={classes.textFieldLeft}
                  onChange={(event) => setHours('tuesday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-4 col-12 text-left">
                <FormControlLabel
                  control={<Checkbox checked={business.hours_of_operation.wednesday.enabled}
                    onChange={enableDay}
                    id="wednesday" />}
                  label="Wednesday"
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="open"
                  type="time"
                  disabled={!business.hours_of_operation.wednesday.enabled}
                  defaultValue={business.hours_of_operation.wednesday.open || "00:00"}
                  className={classes.textFieldRight}
                  onChange={(event) => setHours('wednesday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="close"
                  type="time"
                  disabled={!business.hours_of_operation.wednesday.enabled}
                  defaultValue={business.hours_of_operation.wednesday.close || "23:59"}
                  className={classes.textFieldLeft}
                  onChange={(event) => setHours('wednesday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-4 col-12 text-left">
                <FormControlLabel
                  control={<Checkbox checked={business.hours_of_operation.thursday.enabled}
                    onChange={enableDay}
                    id="thursday" />}
                  label="Thursday"
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="open"
                  type="time"
                  disabled={!business.hours_of_operation.thursday.enabled}
                  defaultValue={business.hours_of_operation.thursday.open || "00:00"}
                  className={classes.textFieldRight}
                  onChange={(event) => setHours('thursday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="close"
                  type="time"
                  disabled={!business.hours_of_operation.thursday.enabled}
                  defaultValue={business.hours_of_operation.thursday.close || "23:59"}
                  className={classes.textFieldLeft}
                  onChange={(event) => setHours('thursday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-4 col-12 text-left">
                <FormControlLabel
                  control={<Checkbox checked={business.hours_of_operation.friday.enabled}
                    onChange={enableDay}
                    id="friday" />}
                  label="Friday"
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="open"
                  type="time"
                  disabled={!business.hours_of_operation.friday.enabled}
                  defaultValue={business.hours_of_operation.friday.open || "00:00"}
                  className={classes.textFieldRight}
                  onChange={(event) => setHours('friday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="close"
                  type="time"
                  disabled={!business.hours_of_operation.friday.enabled}
                  defaultValue={business.hours_of_operation.friday.close || "23:59"}
                  className={classes.textFieldLeft}
                  onChange={(event) => setHours('friday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-4 col-12 text-left">
                <FormControlLabel
                  control={<Checkbox checked={business.hours_of_operation.saturday.enabled}
                    onChange={enableDay}
                    id="saturday" />}
                  label="Saturday"
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="open"
                  type="time"
                  disabled={!business.hours_of_operation.saturday.enabled}
                  defaultValue={business.hours_of_operation.saturday.open || "00:00"}
                  className={classes.textFieldRight}
                  onChange={(event) => setHours('saturday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
              <div className="col-lg-4 col-6 pr-sm-auto pr-0 pl-sm-auto pl-0">
                <TextField
                  id="close"
                  type="time"
                  disabled={!business.hours_of_operation.saturday.enabled}
                  defaultValue={business.hours_of_operation.saturday.close || "23:59"}
                  className={classes.textFieldLeft}
                  onChange={(event) => setHours('saturday', event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                />
              </div>
            </div>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleEdit(setShowEditHours)}>Update</Button>
          <Button variant="light" onClick={() => setShowEditHours(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDisableProfile} onHide={() => setShowDisableProfile(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>DISABLE YOUR PROFILE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              Are you sure you want to disable your account?
              <Form.Check type="checkbox" label="Check me out" />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => {
            setEditProfile({ ...editProfile, active: false });
            handleEdit(setShowDisableProfile);
          }}>Yes</Button>
          <Button variant="light" onClick={() => setShowDisableProfile(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Profile;