import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/images/CoupifyLogo-2.png';
import CampaignAdd from '../pages/Campaign/CampaignAdd';
import CampaignDetails from '../pages/Campaign/CampaignDetails';
import Campaigns from '../pages/Campaign/Campaigns';
import CouponDetails from '../pages/Coupon/CouponDetails';
import Dashboard from '../pages/Dashboard/Dashboard';
import Invoice from '../pages/Invoice/Invoice';
import InvoiceHistory from '../pages/Invoice/InvoiceHistory';
import Profile from '../pages/Profile/Profile';
import PromotionsDetails from '../pages/Promotion/PromotionDetails';
import Promotions from '../pages/Promotion/Promotions';
import services from '../services';
import { setCategory } from '../store/actions';
import NavBar from './Constant/NavBar';
import MenuList from './Menu';
import ProductCampaigns from './ProductCampaigns/ProductCampaigns';
import ProductCouponDetails from './ProductCampaigns/ProductCouponDetails';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  coupifyLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Layout = (props) => {

  const { loadPage, history } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { client } = useSelector(state => state.client);
  const [page, setPage] = useState(loadPage);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth < 767.9) {
      setOpen(false);
    }
  }, []);

  const getCategory = () => {
    services.UserServices.getCategory()
      .then(res => res.json())
      .then(res => {
        dispatch(setCategory(res?.data?.categoryList))
      })
      .catch(err => console.log('getCategory error', err));
  }

  useEffect(() => {
    if (!client._id) {
      history.push('/');
    } else {
    }
  });

  useEffect(() => {
    getCategory()
  }, [])

  const PageLoad = () => {
    switch (page) {
      case "campaigns":
        return <Campaigns {...props} page={page} setPage={setPage} />;
      case "promotions":
        return <Promotions {...props} page={page} setPage={setPage} />;
      case "ProductCampaign":
        return <ProductCampaigns {...props} page={page} setPage={setPage} />;
      case "campaign-details":
        return <CampaignDetails {...props} page={page} setPage={setPage} />
      case "promotion-details":
        return <PromotionsDetails {...props} page={page} setPage={setPage} />
      case "campaign-add":
        return <CampaignAdd  {...props} page={page} setPage={setPage} />
      case "coupon-details":
        return <CouponDetails {...props} page={page} setPage={setPage} loadtype={'C'} />
      case "promotion-coupon-details":
        return <CouponDetails {...props} page={page} setPage={setPage} loadtype={'P'} />
      case "product-coupon-details":
        return <ProductCouponDetails {...props} page={page} setPage={setPage} />
      case "invoices":
        return <Invoice {...props} page={page} setPage={setPage} />
      case "navbar":
        return <NavBar {...props} page={page} setPage={setPage} />
      case "invoice-history":
        return <InvoiceHistory {...props} page={page} setPage={setPage} />
      case "profile":
        return <Profile {...props} page={page} setPage={setPage} />

      default:
        return <Dashboard {...props} page={page} setPage={setPage} />
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="white"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className="headerStyle">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className='p-0'>
            {client.client_name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className="row" style={{ width: '100%' }}>
          <div className={classes.coupifyLogo + " col"}>
            <img src={logo} alt="" width="100" className="cooppyLogo" />
          </div>
          <div className={classes.toolbar + " col-1"} style={{ padding: '0' }}>
            <IconButton onClick={handleDrawerClose} style={{ padding: '0' }}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
        </div>
        <Divider />
        <MenuList {...props} page={page} setPage={setPage} />
      </Drawer>
      <main className={classes.content + " mainContent"}>
        <div className={classes.toolbar} />
        {PageLoad()}
      </main>
    </div>
  );
}

export default Layout;