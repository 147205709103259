import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  textField: {
    paddingLeft: 15
  }
}));

const Step4 = ({business, setNewBusiness}) => {
  const classes = useStyles();


  const enableDay = (event) => {
    setNewBusiness({
      ...business,
      hours_of_operation: {
        ...business.hours_of_operation,
        [event.target.id]: {
          ...business.hours_of_operation[event.target.id],
          enabled: !business.hours_of_operation[event.target.id].enabled
        }
      }
    });
  };

  const setHours = (day, event) => {
    setNewBusiness({
      ...business,
      hours_of_operation: {
        ...business.hours_of_operation,
        [day]: {
          ...business.hours_of_operation[day],
          [event.target.id]: event.target.value
        }
      }
    });
  };

  return (
    <>
      <h5 className="wizard-title py-2">Hours of Operation</h5>
      <FormControl required component="fieldset" className={classes.formControl}>
      <div className="row mb-2">
        <div className="col-md-4 p-2 text-md-left text-center">
          <FormControlLabel
            control={<Checkbox checked={ business.hours_of_operation.sunday.enabled }
            onChange={enableDay}
            id="sunday" />}
            label="Sunday"
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="open"
            type="time"
            value={business.hours_of_operation.sunday.open}
            placeholder="12:00 AM"
            disabled={!business.hours_of_operation.sunday.enabled}
            defaultValue="00:00"
            onChange={(event) => setHours('sunday', event)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="close"
            type="time"
            value={business.hours_of_operation.sunday.close}

            disabled={!business.hours_of_operation.sunday.enabled}
            defaultValue="23:59"
            className={classes.textField}
            onChange={(event) => setHours('sunday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-4 p-2 text-md-left text-center">
          <FormControlLabel
          aria-required
            control={<Checkbox checked={business.hours_of_operation.monday.enabled}
            onChange={enableDay}
            id="monday" />}
            label="Monday"
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="open"
            type="time"
            value={business.hours_of_operation.monday.open}
            disabled={!business.hours_of_operation.monday.enabled}
            defaultValue="00:00"
            className={classes.textField}
            onChange={(event) => setHours('monday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="close"
            type="time"
            value={business.hours_of_operation.monday.close}
            disabled={!business.hours_of_operation.monday.enabled}
            defaultValue="23:59"
            className={classes.textField}
            onChange={(event) => setHours('monday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-4 p-2 text-md-left text-center">
          <FormControlLabel
            control={<Checkbox checked={business.hours_of_operation.tuesday.enabled}
            onChange={enableDay}
            id="tuesday" />}
            label="Tuesday"
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="open"
            type="time"
            value={business.hours_of_operation.tuesday.open}
            disabled={!business.hours_of_operation.tuesday.enabled}
            defaultValue="00:00"
            className={classes.textField}
            onChange={(event) => setHours('tuesday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="close"
            type="time"
            value={business.hours_of_operation.tuesday.close}
            disabled={!business.hours_of_operation.tuesday.enabled}
            defaultValue="23:59"
            className={classes.textField}
            onChange={(event) => setHours('tuesday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-4 p-2 text-md-left text-center">
          <FormControlLabel
            control={<Checkbox checked={business.hours_of_operation.wednesday.enabled}
            onChange={enableDay}
            id="wednesday" />}
            label="Wednesday"
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="open"
            type="time"
            value={business.hours_of_operation.wednesday.open}
            disabled={!business.hours_of_operation.wednesday.enabled}
            defaultValue="00:00"
            className={classes.textField}
            onChange={(event) => setHours('wednesday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="close"
            type="time"
            value={business.hours_of_operation.wednesday.close}
            disabled={!business.hours_of_operation.wednesday.enabled}
            defaultValue="23:59"
            className={classes.textField}
            onChange={(event) => setHours('wednesday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-4 p-2 text-md-left text-center">
          <FormControlLabel
            control={<Checkbox checked={business.hours_of_operation.thursday.enabled}
            onChange={enableDay}
            id="thursday" />}
            label="Thursday"
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="open"
            value={business.hours_of_operation.thursday.open}
            type="time"
            disabled={!business.hours_of_operation.thursday.enabled}
            defaultValue="00:00"
            className={classes.textField}
            onChange={(event) => setHours('thursday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="close"
            type="time"
            value={business.hours_of_operation.thursday.close}
            disabled={!business.hours_of_operation.thursday.enabled}
            defaultValue="23:59"
            className={classes.textField}
            onChange={(event) => setHours('thursday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-4 p-2 text-md-left text-center">
          <FormControlLabel
            control={<Checkbox checked={business.hours_of_operation.friday.enabled}
            onChange={enableDay}
            id="friday" />}
            label="Friday"
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="open"
            type="time"
            value={business.hours_of_operation.friday.open}
            disabled={!business.hours_of_operation.friday.enabled}
            defaultValue="00:00"
            className={classes.textField}
            onChange={(event) => setHours('friday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="close"
            type="time"
            value={business.hours_of_operation.friday.close}
            disabled={!business.hours_of_operation.friday.enabled}
            defaultValue="23:59"
            className={classes.textField}
            onChange={(event) => setHours('friday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-4 p-2 text-md-left text-center">
          <FormControlLabel
            control={<Checkbox checked={business.hours_of_operation.saturday.enabled}
            onChange={enableDay}
            id="saturday" />}
            label="Saturday"
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="open"
            type="time"
            value={business.hours_of_operation.saturday.open}
            disabled={!business.hours_of_operation.saturday.enabled}
            defaultValue="00:00"
            className={classes.textField}
            onChange={(event) => setHours('saturday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
        <div className="col-md-4 p-2 text-md-left text-center">
          <TextField
            id="close"
            type="time"
            value={business.hours_of_operation.saturday.close}
            disabled={!business.hours_of_operation.saturday.enabled}
            defaultValue="23:59"
            className={classes.textField}
            onChange={(event) => setHours('saturday', event)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 5 min
            }}
          />
        </div>
      </div>
      </FormControl>
    </>
  )
}

export default Step4;
