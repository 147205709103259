import config from "../config";

const token = localStorage.getItem('token');

export default  {
  addCampaign: async (campaign) => {
    return await fetch(`${config.apiUrl}/campaigns`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(campaign),
    });
  },
  addPromotion: async (campaign) => {
    return await fetch(`${config.apiUrl}/promotions`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(campaign),
    });
  },
  
  addPromotion: async (campaign) => {
    return await fetch(`${config.apiUrl}/promotions`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(campaign),
    });
  },
  
  addProduct: async (Productcampaign) => {
    return await fetch(`${config.apiUrl}/product`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(Productcampaign),
    });
  },
  uploadImage: async (imageUpload) => {
    return await fetch(`${config.apiUrl}/upload/image`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(imageUpload),
    });
  },
  getCampaign: async (campaignId) => {
    return await fetch(`${config.apiUrl}/campaigns/${campaignId}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getPromotion: async (campaignId) => {
    return await fetch(`${config.apiUrl}/promotions/${campaignId}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  enable_disable_promotion: async (campaignId, value) => {
    return await fetch(`${config.apiUrl}/promotions/enableDisable/${campaignId}/${value}`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getProduct: async (id) => {
    return await fetch(`${config.apiUrl}/product?id=${id}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  updateCampaign: async (campaignId, campaign) => {
    return await fetch(`${config.apiUrl}/campaigns/${campaignId}`, {
      method: 'PATCH',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(campaign),
    });
  },
  updatePromotion: async (campaignId, campaign) => {
    return await fetch(`${config.apiUrl}/promotions/${campaignId}`, {
      method: 'PATCH',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(campaign),
    });
  },

  deleteCampaign: async (campaignId) => {
    return await fetch(`${config.apiUrl}/campaigns/${campaignId}`, {
      method: 'DELETE',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  
  deletePromotion: async (campaignId) => {
    return await fetch(`${config.apiUrl}/promotions/${campaignId}`, {
      method: 'DELETE',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getCampaigns: async (clientId) => {
    const client = JSON.parse(localStorage.getItem('client'));
    return await fetch(`${config.apiUrl}/campaigns/${client._id}/campaigns`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

      },
    });
  },
  getPromotions: async (clientId) => {
    const client = JSON.parse(localStorage.getItem('client'));
    return await fetch(`${config.apiUrl}/promotions/${client._id}/promotions`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

      },
    });
  },

  getProductsall: async (clientId) => {
    const client = JSON.parse(localStorage.getItem('client'));
    return await fetch(`${config.apiUrl}/products?page=1&count=10&search&client_id=${client._id}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getProducts: async () => {
    return await fetch(`${config.apiUrl}/products`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
}



