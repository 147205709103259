/* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import PrintIcon from '@material-ui/icons/Print';
import React, { useEffect, useRef } from 'react';
import ReactToPrint from "react-to-print";
import TableComponent from '../../components/Invoice/TableComponent';

const Invoice = ({ setPage }) => {
  const componentRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={window.innerWidth >= 992 ? 'container' : ''}>
      <div className="row">
        <div className="col pl-md-0 pl-auto pr-md-0 pr-auto">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="javascript:;" onClick={() => setPage('dashboard')}>
              <HomeIcon />
            </Link>
            <Typography color="primary">Current Invoice</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col pl-md-0 pl-auto pr-md-0 pr-auto">
          <Card>
            <CardHeader
              title="Current Invoice"
              action={
                <ReactToPrint
                  trigger={() => (
                    <Tooltip title="Print Invoice">
                      <IconButton aria-label="Print Invoice" color="primary">
                        <PrintIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  suppressErrors='true'
                  content={() => componentRef.current}
                />
              }
              subheader="Active invoice for the current month" />
            <CardContent>
              <TableComponent ref={componentRef} />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Invoice;