import React, { useState } from 'react';
import NavBar from '../Constant/NavBar';
import { Form, Input, Alert, Spin } from 'antd';
import axios from 'axios';
import config from '../../config';

const ContactUs = (props) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false);
  

  const onFinish = async (e) => {
    const payload = {
      name: e.Name,
      email: e.email,
      message: e.message,
      number: e.number,
      subject: e.subject,
    }
  
    try {
      setLoading(true);
      axios.post(`${config.apiUrl}/users/contactus`, payload)
        .then(res => {

          // <Alert message={res.data.message} type="success" />
       
          setResponse(res.data.message)

        })

    } catch (e) {



    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
 
  };
  return (
    <div>
      <NavBar prop={props}/>
      <div className="site-content-contain">
        <div id="content" className="site-content">

          <div className="wrap contactWrap">
            <div id="primary" className="content-area">
              <main id="main" className="site-main" role="main">


                <article id="post-37" className="post-37 page type-page status-publish hentry contactRow">
                  <header className="entry-header">
                    <h1 className="entry-title">contact</h1>
                  </header>
                  <div className="entry-content">
                    <div className="wpforms-container wpforms-container-full" id="wpforms-82">

                      {
                        response === false

                          ?
                          <Spin spinning={loading}>
                            <Form
                              name="basic"
                              onFinish={onFinish}
                              onFinishFailed={onFinishFailed}
                              id="wpforms-form-82" className="wpforms-validate wpforms-form wpforms-ajax-form" data-formid="82" method="post" enctype="multipart/form-data"
                              action="/contact/" data-token="9c48d774b2c822377d2944bba6d0b757" novalidate="novalidate">
                              <noscript className="wpforms-error-noscript">Please enable JavaScript in your browser to complete this form.</noscript>
                              <div className="wpforms-field-container">
                                <div id="wpforms-82-field_0-container" className="wpforms-field wpforms-field-name" data-field-id="0">
                                  <label className="wpforms-field-label" for="wpforms-82-field_0">Name
                                    <span className="wpforms-required-label">*</span></label>
                                
                                      <Form.Item
                                        name="Name"
                                        className="w-100"
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please input your name!',
                                          },
                                        ]}
                                      >
                                        <Input type="text"
                                          id="wpforms-82-field_0"
                                          className="wpforms-field-name-first wpforms-field-required"
                                          name="wpforms[fields][0][first]"
                                          required="" />

                                      </Form.Item>

                                    </div><div id="wpforms-82-field_1-container" className="wpforms-field wpforms-field-email" data-field-id="1">
                                  <label className="wpforms-field-label" for="wpforms-82-field_1">Email <span className="wpforms-required-label">*</span></label>
                                  <Form.Item
                                    name="email"
                                    rules={[
                                      {
                                        required: true,
                                        type:"email",
                                        message: 'Please input your Right email!',
                                      },
                                    ]}
                                  >
                                    <Input type="email" id="wpforms-82-field_1" className="wpforms-field-medium wpforms-field-required" name="wpforms[fields][1]" required="" />

                                  </Form.Item>
                                  <label className="wpforms-field-label" for="wpforms-82-field_1">Phone Number <span className="wpforms-required-label">*</span></label>

                                  <Form.Item
                                    name="number"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your number!',
                                      },
                                    ]}
                                  >
                                    
                                    <input
                                    maxLength={12}
                                    minlength="8"
                                    type="text" 
                                    id="wpforms-82-field_1"
                                     className="wpforms-field-medium wpforms-field-required" name="wpforms[fields][1]" required="" />

                                  </Form.Item>
                                  <label className="wpforms-field-label" for="wpforms-82-field_1">Subject <span className="wpforms-required-label">*</span></label>

                                  <Form.Item
                                    name="subject"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your subject!',
                                      },
                                    ]}
                                  >
                                    <Input type="text" id="wpforms-82-field_1" className="wpforms-field-medium wpforms-field-required" name="wpforms[fields][1]" required="" />

                                  </Form.Item>
                                </div>
                                <div id="wpforms-82-field_2-container" className="wpforms-field wpforms-field-textarea" data-field-id="2">
                                  <label className="wpforms-field-label" for="wpforms-82-field_2">Comment or Message <span className="wpforms-required-label">*</span></label>
                                  <Form.Item
                                    name="message"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your message!',
                                      },
                                    ]}
                                  >
                                    <Input.TextArea id="wpforms-82-field_2" className="wpforms-field-medium wpforms-field-required" name="wpforms[fields][2]" required="">
                                    </Input.TextArea >
                                  </Form.Item>

                                </div><div id="wpforms-82-field_4-container" className="wpforms-field wpforms-field-checkbox" data-field-id="4">
                                  <label className="wpforms-field-label" for="wpforms-82-field_4">Would you like to join our email list?</label>



                                  <label className="wpforms-field-label-inline" for="wpforms-82-field_4_1">
                                    <input type="checkbox" id="wpforms-82-field_4_1" name="wpforms[fields][4][]" value="Yes, sign me up!" />

                                    Yes, sign me up!</label>


                                </div></div><div className="wpforms-submit-container"><input type="hidden" name="wpforms[id]" value="82" /><input type="hidden" name="wpforms[author]" value="1" /><input type="hidden" name="wpforms[post_id]" value="37" /><button type="submit" className="submit_btn" >Submit</button>
                                {/* <img src="https://secureservercdn.net/50.62.172.157/d22.b3a.myftpupload.com/wp-content/plugins/wpforms-lite/assets/images/submit-spin.svg" className="wpforms-submit-spinner form_container" alt="" /> */}
                                </div>
                            </Form>
                          </Spin>
                          :
                          <div className="alert alert-warning" role="alert">
                            {response}
                          </div>
                      }

                    </div>  	</div>
                </article>

              </main>
            </div>
          </div>


        </div>

        <footer id="colophon" className="site-footer" role="contentinfo">
          <div className="wrap">

          </div>
        </footer>
      </div>
    </div>

  );
};

export default ContactUs;