import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import HomeIcon from "@material-ui/icons/Home";
import { List, Upload } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import {
  DateRangePicker,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useCount } from "../../categories";
import ChartSkeleton from "../../components/ChartSkeleton";
import CouponTable from "../../components/Coupon/CouponTable";
import { getFileDimensions } from "../../helper/getFileDimensions";
import { uploadFileOnS3 } from "../../helper/s3Uploader";
import { validateCoupon } from "../../resources/Validations";
import services from "../../services/index";
toast.configure();

const CampaignDetails = (props) => {
  const cam = useSelector((state) => state.client.campaign);
  const { category } = useSelector((state) => state.client);
  const { setPage } = props;
  const couponImage = require("../../assets/images/logo-placeholder.png");
  const [campaign, setCampaign] = useState(cam);
  const [loading, setLoading] = useState(false);
  const [couponPic, setCouponPic] = useState("");
  const [couponForm, setCouponForm] = useState({});
  const [editCampaign, setEditCampaign] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (fileList.length > 5) {
      setErrorMessage("You can only upload up to 5 files.");
    } else {
      setErrorMessage("");
    }
  }, [fileList]);

  const handlePreview = (file) => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    if (isImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result;
      };
      reader.readAsDataURL(file);
    } else if (isVideo) {
    }
  };

  const handleRemoveFile = (file) => {
    setFileList((prevFileList) => prevFileList.filter((item) => item !== file));
    const filteredImages = couponForm.images.filter((item) => item !== file);
    setCouponForm({
      ...couponForm,
      images: filteredImages,
    });
    setErrorMessage("");
  };

  let tempFile = [];
  const propsUploadFile = {
    onRemove: (file) => {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item !== file)
      );
      const filteredImages = couponForm.images.filter((item) => item !== file);
      setCouponForm({
        ...couponForm,
        images: filteredImages,
      });
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        setErrorMessage("File size exceeds the maximum allowed size (50MB).");
        return false;
      } else {
        setErrorMessage("");
      }

      // If it's an image or video, add it to the fileList
      if (isImage || isVideo) {
        setFileList((prevFileList) => [...prevFileList, file]);
        tempFile.push(file);

        setCouponForm({
          ...couponForm,
          images: couponForm?.images
            ? [...couponForm.images, ...tempFile]
            : [...tempFile],
        });
      } else {
        setErrorMessage(
          "Invalid file type. Only images and videos are allowed."
        );
        return false;
      }
      return false;
    },
    fileList,
    multiple: true,
    showUploadList: false,
  };

  const notify = (type, text) =>
    toast[type](text, {
      position: toast.POSITION.TOP_RIGHT,
    });

  const resetForm = () => {
    setShowCreate(false);
    setCouponForm({ coupons_type: "C" });
    setErrorMessage("");
  };

  const handleCreate = async () => {
    setLoading(true);
    const dimensions = await getFileDimensions(fileList[0]);

    await uploadFileOnS3(fileList, dimensions, "campaign")
      .then((res) => {
        const files = res.map((item) => {
          return { images_url: item.Location };
        });

        couponForm.coupons_type = "C";
        couponForm.images = files;

        services.CouponServices.createCoupon(couponForm)
          .then((response) => response)
          .then((response) => {
            setShowCreate(false);
            setCouponForm({ coupons_type: "C" });
            setCouponPic("");
            setFileList([]);

            setCouponForm({ coupons_type: "C" });
            getCampaign();
            setLoading(false);
            notify("success", "Offer created successfully.");
            // setCouponPic(couponImage);
            setErrorMessage("");
          })
          .catch((err) => {
            notify("error", "Something went wrong.");
            console.log("create coupon error:", err);
            setLoading(false);
            setErrorMessage("");
          });
      })
      .catch((error) => {
        notify("error", "Something went wrong.");
        console.log("image upload error", error);
        setLoading(false);
        setErrorMessage("");
      });
  };

  const handleDelete = () => {
    services.CampaignServices.deleteCampaign(campaign._id)
      .then((response) => {
        setPage("campaigns");
      })
      .catch((err) => console.log("Delete Campaign error: ", err));
  };

  const handleEdit = () => {
    services.CampaignServices.updateCampaign(campaign._id, editCampaign)
      .then((res) => res.json())
      .then((res) => {
        if (res.err) {
          notify("error", res.message);
          return false;
        }

        notify("success", "Campaign updated successfully.");
        setCampaign(res.data.campaign);
        setShowEdit(false);
      })
      .catch((err) => console.log("Update Campaign error:", err));
  };

  const getCampaign = async () => {
    await services.CampaignServices.getCampaign(campaign._id)
      .then((res) => res.json())
      .then((res) => {
        setCampaign(res.data.campaign);
        setCouponForm({
          couponForm,
          campaign: res.data.campaign._id,
          client: res.data.campaign.client,
        });
        setEditCampaign({
          ...editCampaign,
          campaign_start_date: moment(campaign.campaign_start_date).utc(),
          campaign_expiration_date: moment(
            campaign.campaign_expiration_date
          ).utc(),
        });
        // setCouponForm({})
      })
      .catch((err) => console.log("getCampaign", err));
  };

  useEffect(() => {
    getCampaign();
  }, [campaign.coupons.length]);

  let newCouponStartDate;
  if (moment(campaign.campaign_start_date).unix() > moment().unix()) {
    newCouponStartDate = moment(campaign.campaign_start_date).subtract(
      1,
      "days"
    );
  } else {
    newCouponStartDate = moment().subtract(1, "days");
  }

  const addOffer = () => {
    setShowCreate(true);
  };

  const setRunPeriod = (startDate, endDate) => {
    var newEndDate = endDate != null ? moment(endDate) : moment(startDate);
    setEditCampaign({
      ...editCampaign,
      campaign_start_date: moment(startDate),
      campaign_expiration_date: newEndDate,
    });
  };

  const onCloseModal = () => {
    setShowCreate(false);
    setFileList([]);
    setCouponForm({
      ...couponForm,
      image: "",
      coupon_name: "",
      start_date: "",
      coupon_expiration: "",
      barcode: "",
      coupon_description: "",
      category: "",
      subcategory: "",
      use_count: "",
    });
    setLoading(false);
    setErrorMessage("");
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                href="javascript:;"
                onClick={() => setPage("dashboard")}
              >
                <HomeIcon />
              </Link>
              <Link
                color="inherit"
                href="javascript:;"
                onClick={() => setPage("campaigns")}
              >
                <Typography color="primary">Campaigns</Typography>
              </Link>
              <Typography color="textPrimary">Campaign Details</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            {campaign ? (
              <Card>
                <CardHeader
                  title="Campaign Details"
                  action={
                    <>
                      <IconButton
                        aria-label="edit campaign"
                        title="Edit Campaign"
                        onClick={() => setShowEdit(true)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete campaign"
                        title="Delete Campaign"
                        onClick={() => setShowDelete(true)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  }
                  subheader="Manage campaign details and create offers"
                />
                <CardContent style={{ paddingBottom: "16px" }}>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                      <h6>
                        <u>Campaign Name</u>
                      </h6>
                      <p>{campaign.campaign_name}</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <h6>
                        <u>Campaign Description</u>
                      </h6>
                      <p>{campaign.campaign_description}</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <h6>
                        <u>Run Time</u>
                      </h6>
                      <p>
                        {moment(campaign.campaign_start_date).format(
                          "MMM Do YYYY"
                        )}{" "}
                        -{" "}
                        {moment(campaign.campaign_expiration_date).format(
                          "MMM Do YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <ChartSkeleton />
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            {campaign ? (
              <Card>
                <CardHeader
                  style={{ paddingBottom: "0" }}
                  title={"Offers (" + campaign.coupons.length + ")"}
                  action={
                    <IconButton
                      title="Add Offer"
                      aria-label="Create Coupon"
                      onClick={() => addOffer()}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  }
                  subheader={
                    'Offer associated with "' + campaign.campaign_name + '".'
                  }
                />
                <CardContent>
                  <CouponTable
                    {...props}
                    headings={["Name", "Run Time", "Status", "View"]}
                    campaign={campaign}
                    pagetype={"C"}
                  />
                </CardContent>
              </Card>
            ) : (
              <ChartSkeleton />
            )}
          </div>
        </div>
      </div>
      <Modal centered show={showCreate} onHide={() => onCloseModal()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>CREATE A OFFER</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <Form id="couponForm" encType="multipart/form-data">
              <div className="row mt-3">
                <div className="col-lg-6 col-12" controlId="name">
                  <Form.Label>Offer Name</Form.Label>
                  <Form.Control
                    className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                    placeholder="Offer Name"
                    type="text"
                    value={couponForm.coupon_name || ""}
                    onChange={(event) =>
                      setCouponForm({
                        ...couponForm,
                        coupon_name: event.target.value,
                      })
                    }
                  />
                </div>
                <div
                  className="col-lg-6 col-12 mt-3 mt-lg-0"
                  controlId="startDate"
                >
                  <Form.Label>Offer Run Period</Form.Label>
                  <DateRangePicker
                    orientation={
                      window.innerWidth >= 768 ? "horizontal" : "vertical"
                    }
                    startDateId="startDate"
                    endDateId="endDate"
                    isOutsideRange={(day) =>
                      isInclusivelyAfterDay(
                        day,
                        moment(campaign.campaign_expiration_date).add(1, "day")
                      ) || isInclusivelyBeforeDay(day, newCouponStartDate)
                    }
                    startDate={couponForm.start_date}
                    endDate={couponForm.coupon_expiration}
                    onDatesChange={({ startDate, endDate }) =>
                      setCouponForm({
                        ...couponForm,
                        start_date: startDate,
                        coupon_expiration: endDate,
                      })
                    }
                    focusedInput={focusedInput}
                    onFocusChange={(focused) => setFocusedInput(focused)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-12 mt-3" controlId="description">
                  <Form.Label>Offer Description</Form.Label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={couponForm.coupon_description}
                    onChange={(event) =>
                      setCouponForm({
                        ...couponForm,
                        coupon_description: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-lg-6 col-12 mt-3" controlId="barcode">
                  <Form.Label>Barcode</Form.Label>
                  <Form.Control
                    className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                    placeholder="Barcode #"
                    value={couponForm.barcode}
                    onChange={(event) =>
                      setCouponForm({
                        ...couponForm,
                        barcode: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-12 mt-3" controlId="category">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    style={{ height: 48 }}
                    value={couponForm.category}
                    onChange={(event) => {
                      setCouponForm({
                        ...couponForm,
                        category: event.target.value,
                      });
                      setSubCategories(
                        category.filter(
                          (sub) => sub.category == event.target.value
                        )
                      );
                    }}
                  >
                    <option>Choose a category</option>
                    {category.map((cat, index) => (
                      <option key={index} value={cat.category}>
                        {cat.category}
                      </option>
                    ))}
                  </Form.Control>
                </div>
                <div className="col-lg-4 col-12 mt-3" controlId="subcategory">
                  <Form.Label>Sub-Category</Form.Label>
                  <Form.Control
                    as="select"
                    style={{ height: 48 }}
                    value={couponForm.subcategory}
                    onChange={(event) =>
                      setCouponForm({
                        ...couponForm,
                        subcategory: event.target.value,
                      })
                    }
                  >
                    {<option>Choose a category first</option>}
                    {!_.isEmpty(couponForm.category) &&
                      _.isEmpty(subCategories?.[0]?.subcategory) && (
                        <option>There are no subcategories</option>
                      )}
                    {subCategories[0]?.subcategory.map((subcategory, index) => (
                      <option key={index} value={subcategory}>
                        {subcategory}
                      </option>
                    ))}
                  </Form.Control>
                </div>
                <div className="col-lg-4 col-12 mt-3">
                  <Form.Label>Use Per Customer</Form.Label>
                  <Form.Control
                    as="select"
                    style={{ height: 48 }}
                    value={couponForm.use_count}
                    onChange={(event) =>
                      setCouponForm({
                        ...couponForm,
                        use_count: event.target.value,
                      })
                    }
                  >
                    {useCount.map((use, index) => (
                      <option key={index} value={use.id}>
                        {use.name}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12" controlId="image">
                  <div>
                    <Form.Label>Offer Image / Video (50 MB max.)</Form.Label>
                    <Upload
                      {...propsUploadFile}
                      onPreview={handlePreview}
                      accept="image/*,video/*"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          className="choose_file_button"
                          onClick={() => setErrorMessage("")}
                        >
                          Choose file
                        </Button>
                      </div>
                    </Upload>
                    {errorMessage && (
                      <div className="mt-1 text-danger">{errorMessage}</div>
                    )}
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <Form.Label>Offer Preview</Form.Label>
                  {fileList.length === 0 && (
                    <img
                      src={couponImage}
                      alt="Coupon"
                      width="150px"
                      height="100px"
                    />
                  )}
                  <List
                    grid={{ gutter: 16 }}
                    dataSource={fileList}
                    renderItem={(file) => (
                      <List.Item
                        style={{
                          position: "inherit",
                          zIndex: 0,
                          display: "flex",
                        }}
                      >
                        {file.type.startsWith("image/") ? (
                          <div className="position-relative">
                            <div
                              className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveFile(file)}
                            >
                              X
                            </div>
                            <img
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        ) : (
                          <div className="position-relative">
                            <div
                              className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveFile(file)}
                            >
                              X
                            </div>
                            <video
                              controls
                              src={URL.createObjectURL(file)}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        )}
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              disabled={validateCoupon(couponForm) || loading || errorMessage}
              onClick={() => handleCreate()}
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              Create Offer
            </Button>
            <Button
              variant="light"
              onClick={() => onCloseModal()}
              style={{ marginLeft: "20px" }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        centered
        show={showEdit}
        onHide={() => setShowEdit(false)}
        size="lg"
        className="edit_campaign_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>EDIT A CAMPAIGN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3">
            <div className="col-lg-6 col-12">
              <Form.Label>Campaign Name</Form.Label>
              <Form.Control
                className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                placeholder="Campaign Name"
                type="text"
                defaultValue={
                  editCampaign.campaign_name || campaign.campaign_name
                }
                onChange={(e) =>
                  setEditCampaign({
                    ...editCampaign,
                    campaign_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-6 col-12 mt-3 mt-lg-0">
              <Form.Label>Campaign Run Period</Form.Label>
              <DateRangePicker
                orientation={
                  window.innerWidth >= 768 ? "horizontal" : "vertical"
                }
                startDateId="startDate"
                endDateId="endDate"
                startDate={
                  editCampaign.campaign_start_date ||
                  campaign.campaign_start_date
                }
                endDate={
                  editCampaign.campaign_expiration_date ||
                  campaign.campaign_expiration_date
                }
                //onDatesChange={({ startDate, endDate }) => setEditCampaign({ ...editCampaign, campaign_start_date: startDate,  campaign_expiration_date: endDate })}
                onDatesChange={({ startDate, endDate }) =>
                  setRunPeriod(startDate, endDate)
                }
                focusedInput={focusedInput}
                onFocusChange={(focused) => setFocusedInput(focused)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <Form.Label>Campaign Description</Form.Label>
              <Form.Control
                name="description"
                onChange={(e) =>
                  setEditCampaign({
                    ...editCampaign,
                    campaign_description: e.target.value,
                  })
                }
                as="textarea"
                rows="3"
                defaultValue={
                  editCampaign.campaign_description ||
                  campaign.campaign_description
                }
              ></Form.Control>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleEdit()}>
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"
              />
            )}
            Update Campaign
          </Button>
          <Button
            variant="light"
            onClick={() => setShowEdit(false)}
            style={{ marginLeft: "20px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        show={showDelete}
        onHide={() => setShowDelete(false)}
        size="md"
      >
        <Modal.Header closeButton variant="danger">
          <Modal.Title>DELETE A CAMPAIGN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete <b>"{campaign.campaign_name}"</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDelete()}>
            Yes
          </Button>
          <Button variant="light" onClick={() => resetForm(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CampaignDetails;
