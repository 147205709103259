import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

const WeeklyUsageChart = ({ data }) => {
  let weekData = [];
  let weeks = [];

  for (var i = 0; i < 4; i++) {
    const start = moment().subtract(i, 'weeks').startOf('week').format('M/DD/Y');
    const end = moment().subtract(i, 'weeks').endOf('week').format('M/DD/Y');
    const usage = data.filter(use => moment(use.created_at).isBetween(start, end));
    weeks.push(start + '-<br />' + end);
    weekData.push([`${start} - ${end}`, usage.length]);
  }

  const options = {
		chart: {
      type: 'bar'
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: weeks
    },
    series: [{
      name: 'Weekly Redemptions',
      data: weekData
    }],
    credits: {
      enabled: false
   }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default WeeklyUsageChart;