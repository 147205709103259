import React from 'react';
import NavBar from '../Constant/NavBar';
import { Link } from "react-router-dom";

const Home = (props) => {


  return (
    <div>
      <NavBar prop={props} />
      <div className="site-content-contain">
        <div id="content" className="site-content">

          <div id="primary" className="content-area">
            <main id="main" className="site-main" role="main">

              <article id="post-157" className="twentyseventeen-panel  post-157 page type-page status-publish hentry">


                <div className="panel-content">
                  <div className="wrap pt-0">


                    <div className="entry-content">
                      <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid vc_custom_1638799538280 vc_row-no-padding vc_row-o-content-middle vc_row-flex" className="home_div" ><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner "><div className="wpb_wrapper">
                        <img src="./assets/img/topbanner.png" className="w-100" />
                        <img src="./assets/img/grid.png" className="w-100" />
                        <img src="./assets/img/s3.png" className="w-100" />
                        <img src="./assets/img/s4.png" className="w-100" />
                        <img src="./assets/img/s5.png" className="w-100" />
                        <img src="./assets/img/s6.png" className="w-100" />
                        <img src="./assets/img/s7.png" className="w-100" />

                      </div></div></div></div><div className="vc_row-full-width vc_clearfix"></div>



                    </div>

                    <div className="mainFooter">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6 text-center mb-3">
                            <img className="justImg" src="./assets/img/logo.png" />
                            <br />
                            <Link className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-grey" to="auth" title="" to="/auth" >Get Started Today</Link>
                          </div>

                          <div className="col-md-6 text-center mb-3">
                            <img className="justImg" src="./assets/img/logo.png" />
                            <br />
                            <Link className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-grey" to="/contact" title="Contact">Contact Us</Link>
                          </div>
                        </div>
                      </div>
                    </div>




                  </div>
                </div>

              </article>


            </main>
          </div>


        </div>

        <footer id="colophon" className="site-footer" role="contentinfo">
          <div className="wrap">

          </div>
        </footer>
      </div>

    </div>
  );
};

export default Home;