import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { setBusiness } from '../../store/actions/index';
import { signUpStep1, signUpStep2, signUpStep3, signUpStep4, signUpStep5 } from '../../resources/Validations';
import services from "../../services";
import Step1 from './Step1Page';
import Step2 from './Step2Page';
import Step3 from './Step3Page';
import Step4 from './Step4Page';
import Step5 from './Step5Page';
import SigninForm from './SigninForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'none'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  a: {
    fontSize: 12
  }
}));

const SignupForm = ({ history, setPage }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [business, setNewBusiness] = useState({


    hours_of_operation: {
      sunday: { enabled: false, open: '', close: '' },
      monday: { enabled: false, open: '', close: '' },
      tuesday: { enabled: false, open: '', close: '' },
      wednesday: { enabled: false, open: '', close: '' },
      thursday: { enabled: false, open: '', close: '' },
      friday: { enabled: false, open: '', close: '' },
      saturday: { enabled: false, open: '', close: '' },
    }
  });


  const getSteps = () => ['Credentials', 'Business Details', 'Contact Info', 'Hours of Operation', 'Final'];
  const steps = getSteps();

  const notify = (type, text) => toast[type](text, {
    position: toast.POSITION.TOP_RIGHT
  });

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <Step1 business={business} setNewBusiness={setNewBusiness} />;
      case 1:
        return <Step2 business={business} setNewBusiness={setNewBusiness} />;
      case 2:
        return <Step3 business={business} setNewBusiness={setNewBusiness} />;
      case 3:
        return <Step4 business={business} setNewBusiness={setNewBusiness} />;
      case 4:
        return <Step5 business={business} setNewBusiness={setNewBusiness} />;
      default:
        return <Step1 business={business} setNewBusiness={setNewBusiness} />;
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    services.UserServices.register(business)
      .then(response => response.json())
      .then(response => {

        if (response.err) {
          notify('error', response.message);
          return false;
        }
        notify('success', response.message);
        const { client, expiresIn, token } = response.data;
        localStorage.setItem('isAuth', true);
        localStorage.setItem('client', JSON.stringify(client));
        localStorage.setItem('expiresAt', expiresIn);
        localStorage.setItem('token', token);
        dispatch(setBusiness(client));
        history.push(setPage('signin'));

      });
  }

  const nextButton = _ => {
    if (activeStep < 4) {
      let disable;
      switch (activeStep) {
        case 0:
          disable = signUpStep1(business);
          break;
        case 1:
          disable = signUpStep2(business);
          break;
        case 2:
          disable = signUpStep3(business);
          break;
        case 3:
          disable = signUpStep4(business);
          break;
        default:
          disable = false;
          break;
      }
      return <button className="btn btn-primary btn-block" disabled={business.businessType && business.businessType[0] === "Storefront" ? disable : "" || disable} type="button" onClick={handleNext}>Next</button>;
    }
    return <button className="btn btn-success btn-block" disabled={signUpStep5(business)} type="button" onClick={handleSubmit}>Create Account</button>
  }

  return (
    <div className={classes.root}>

      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className="form-row">
        <div className="col">
          {getStepContent(activeStep)}
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6 p-2">
          <button className="btn btn-secondary btn-block" disabled={activeStep === 0} type="button" onClick={handleBack}>Back</button>
        </div>
        <div className="col-md-6 p-2">
          {nextButton()}
        </div>
      </div>
      <div className="text-center mt-3">
        <Button size="large" variant="outlined" color="dark" onClick={() => setPage('signin')}>SIGN IN</Button>
        {/* <Button size="large" variant="outlined" color="secondary" onClick={() => setPage('forgot')}>FORGOT PASSWORD</Button> */}
      </div>
    </div>
  );
}

export default SignupForm;