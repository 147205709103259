export const SET_AUTH = 'SET_AUTH';

export const setAuth = auth => ({
  type: SET_AUTH,
  auth
});

export const SET_BUSINESS = 'SET_BUSINESS';

export const setBusiness = business => ({
  type: SET_BUSINESS,
  business
});

export const SET_CAMPAIGN = 'SET_CAMPAIGN';

export const setCurrentCampaign = campaign => ({
  type: SET_CAMPAIGN,
  campaign
});

export const SET_PRODUCT = 'SET_PRODUCT';

export const setCurrentProduct = product => ({
  type: SET_PRODUCT,
  product
});


export const SET_COUPON = 'SET_COUPON';

export const setCurrentCoupon = coupon => ({
  type: SET_COUPON,
  coupon
});

export const SET_CATEGORY = 'SET_CATEGORY';

export const setCategory = category => ({
  type: SET_CATEGORY,
  category
});
   