import CampaignServices from './CampaignServices';
import CouponServices from './CouponServices';
import UsageServices from './UsageServices';
import UserServices from './UserServices';

export default {
  CampaignServices,
  CouponServices,
  UsageServices,
  UserServices
};