import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';

import services from "../../services";
import ForgotPasswordForm from './ForgotPasswordForm';
import VerificationReset from './VerificationReset';

const ForgotPassword = ({ history, setPage }) => {

  const [activeStep, setActiveStep] = useState(0);
  const [passwordReset, setPasswordReset] = useState({});

  const notify = (type, text) => toast[type](text, {
    position: toast.POSITION.TOP_RIGHT
  });

  const forgot = email => {
    services.UserServices.forgotPassword(email)
      .then(res => res.json())
      .then(res => {
        if (res.message === 'A verification code has been sent to your email.') {
          notify('success', 'A verification code has been sent to your email.');
          setPasswordReset({ ...passwordReset, id: res.data.id });
        }
        if (res.err) {
          notify('error', res.message);
          return;
        }


      });
  }

  const resetPassword = () => {
    services.UserServices.verifyResetPassword(passwordReset)
      .then(res => res.json())
      .then(res => {
        if (res.err) {
          notify('error', res.message);
          return false;
        }
        notify('success', res.message);
        setPage('signin');
      });
  };

  const getContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <ForgotPasswordForm passwordReset={passwordReset} setPasswordReset={setPasswordReset} forgot={forgot} setActiveStep={setActiveStep} />
      case 1:
        return <VerificationReset passwordReset={passwordReset} setPasswordReset={setPasswordReset} resetPassword={resetPassword} />
      default:
        return <ForgotPasswordForm passwordReset={passwordReset} setPasswordReset={setPasswordReset} forgot={forgot} setActiveStep={setActiveStep} />
    }
  }

  return (
    <div className="">
      {getContent(activeStep)}
      <div className="form-row">
        <div className="col-md-6 p-2">
          <Button size="large" variant="outlined" color="primary" onClick={() => setPage('signin')}>SIGN IN</Button>
        </div>
        <div className="col-md-6 p-2">
          <Button size="large" variant="outlined" color="secondary" onClick={() => setPage('signup')}>SIGN UP</Button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;