import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HomeIcon from "@material-ui/icons/Home";
import { List, Upload } from "antd";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ChartSkeleton from "../../components/ChartSkeleton";
import config from "../../config";
import { getFileDimensions } from "../../helper/getFileDimensions";
import { uploadFileOnS3 } from "../../helper/s3Uploader";
import { validateProduct } from "../../resources/Validations";
import services from "../../services";
import ProductTable from "./ProductTable";

toast.configure();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #f2f2f2",
  },
}));

const Campaigns = (props) => {
  const couponImage = require("../../assets/images/logo-placeholder.png");
  const classes = useStyles();
  const { setPage } = props;
  const theme = useTheme();
  const [value, setValue] = React.useState(1);
  const { client, category } = useSelector((state) => state.client);
  const [campaignForm, setCampaignForm] = useState({});
  const [future, setFuture] = useState([]);
  const [past, setPast] = useState([]);
  const [present, setPresent] = useState([]);
  const [selectMonthFilter, setSelectMonthFilter] = useState("");
  const [output1, setOutput1] = useState("");
  const [showCreate, setShowCreate] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [pro, setPro] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (fileList.length > 5) {
      setErrorMessage("You can only upload up to 5 files.");
    } else {
      setErrorMessage("");
    }
  }, [fileList]);

  const handlePreview = (file) => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    if (isImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result;
      };
      reader.readAsDataURL(file);
    } else if (isVideo) {
    }
  };

  const handleRemoveFile = (file) => {
    setFileList((prevFileList) => prevFileList.filter((item) => item !== file));
    const filteredImages = campaignForm.images.filter((item) => item !== file);
    setCampaignForm({
      ...campaignForm,
      images: filteredImages,
    });
    setErrorMessage("");
  };

  let tempFile = [];
  const propsUploadFile = {
    onRemove: (file) => {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item !== file)
      );

      const filteredImages = campaignForm.images.filter(
        (item) => item !== file
      );
      setCampaignForm({
        ...campaignForm,
        images: filteredImages,
      });
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        setErrorMessage("File size exceeds the maximum allowed size (50MB).");
        return false;
      } else {
        setErrorMessage("");
      }

      // If it's an image or video, add it to the fileList
      if (isImage || isVideo) {
        setFileList((prevFileList) => [...prevFileList, file]);
        tempFile.push(file);
        setCampaignForm({
          ...campaignForm,
          images: campaignForm?.images
            ? [...campaignForm.images, ...tempFile]
            : [...tempFile],
        });
      } else {
        setErrorMessage(
          "Invalid file type. Only images and videos are allowed."
        );
        return false;
      }
      return false;
    },
    fileList,
    multiple: true,
    showUploadList: false,
  };

  const notify = (type, text) =>
    toast[type](text, {
      position: toast.POSITION.TOP_RIGHT,
    });

  const handleCreate = async () => {
    setLoading(true);
    const dimensions = await getFileDimensions(fileList[0]);

    campaignForm.client_id = client._id;
    campaignForm.url = campaignForm.url.toLowerCase()

    await uploadFileOnS3(fileList, dimensions, "product")
      .then((res) => {
        const files = res.map((item) => {
          return { images_url: item.Location };
        });
        campaignForm.images = files;

        services.CampaignServices.addProduct(campaignForm)
          .then((response) => response.json())
          .then((response) => {
            if (response.err) {
              setLoading(false);
              notify("error", response.message);
              setErrorMessage("");
              return;
            }
            if (response.message === "Product created successfully !!") {
              notify("success", "Product created successfully.");
              setShowCreate(false);
              setCampaignForm({});
              setFileList([]);
              setPro(response);
              getProduct();
              getProductCoupon();
              setLoading(false);
              setErrorMessage("");
            }
          })
          .catch((err) => {
            notify("error", "Something went to wrong.");
            console.log("add product error", err);
            setLoading(false);
            setErrorMessage("");
          });
      })
      .catch((error) => {
        notify("error", "Something went to wrong.");
        console.log("image upload error", error);
        setLoading(false);
        setErrorMessage("");
      });
  };

  const getProductCoupon = () => {
    const current = new Date().toUTCString();
    const apast = [],
      apresent = [],
      afuture = [];

    services.CampaignServices.getProduct()
      .then((res) => res.json())
      .then((res) => {
        res.data.campaigns.map((campaign) => {
          const campaign_start_date = new Date(
            campaign.campaign_start_date
          ).toUTCString();
          const campaign_expire_date = new Date(
            campaign.campaign_expiration_date
          ).toUTCString();

          if (moment(campaign_expire_date).isBefore(current))
            apast.push(campaign); //Populate past campaigns
          if (
            moment(campaign_start_date).isSameOrBefore(current) &&
            moment(campaign_expire_date).isSameOrAfter(current)
          )
            apresent.push(campaign); // Populate present campaigns
          if (moment(campaign_start_date).isAfter(current))
            afuture.push(campaign); // Populate future campaigns

          return true;
        });
        setPast(apast);
        setPresent(apresent);
        setFuture(afuture);
      })
      .catch((err) => console.log("getCampaigns error", err));
  };

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = () => {
    const current = new Date().toUTCString();
    const apast = [],
      apresent = [],
      afuture = [],
      arr = [];

    services.CampaignServices.getProductsall()
      .then((res) => res.json())
      .then((res) => {
        res.products.map((campaign) => {
          const array = res.products;

          const campaign_start_date = new Date(
            campaign.campaign_start_date
          ).toUTCString();
          const campaign_expire_date = new Date(
            campaign.campaign_expiration_date
          ).toUTCString();

          if (moment(campaign_expire_date).isBefore(current))
            apast.push(campaign); //Populate past campaigns
          if (
            moment(campaign_start_date).isSameOrBefore(current) &&
            moment(campaign_expire_date).isSameOrAfter(current)
          )
            arr.push(campaign); // Populate present campaigns
          if (moment(campaign_start_date).isAfter(current))
            afuture.push(campaign); // Populate future campaigns
          setPresent(array);

          return true;
        });
        setPast(apast);
        setFuture(afuture);
      })
      .catch((err) => console.log("getCampaigns error", err));
  };

  const resetForm = () => {
    setShowCreate(false);
    setCampaignForm({});
    setFileList([]);
    setErrorMessage("");
  };

  const onCloseModal = () => {
    setCampaignForm({});
    setFileList([]);
    setShowCreate(false);
    setLoading(false);
    setErrorMessage("");
  };

  useEffect(() => {
    setOutput([]);
  }, [input]);

  useEffect(() => {
    // setPro(present)
    setOutput1([]);
    present.filter((val) => {
      if (
        val.category &&
        val.category.toLowerCase().includes(selectMonthFilter.toLowerCase())
      ) {
        setOutput1((output) => [...output, val]);
      }
    });
    getData();
  }, [selectMonthFilter]);

  const token = localStorage.getItem("token");
  const getData = () => {
    const client = JSON.parse(localStorage.getItem("client"));
    fetch(
      `${config.apiUrl}/products?page=1&count=10&search=${selectMonthFilter ? "" : input
      }&client_id=${client._id}&subcategory&category=${selectMonthFilter}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        setPro(json.products);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Products</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div
        className="row my-3 align-items-center"
        style={{ marginLeft: "0", marginRight: "0" }}
      >
        <div className="col-md-7 col-12 pl-0 pr-0">
          <h4>Products</h4>
        </div>
        <div className="col-md-5 col-12 text-left text-md-right pl-0 pr-0">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<AddCircleIcon />}
            onClick={(_) => setShowCreate(true)}
          >
            PRODUCT
          </Button>
        </div>
      </div>
      <div className="row mt-3" style={{ marginLeft: "0", marginRight: "0" }}>
        <div className={"col pl-0 pr-0 " + classes.root}>
          {past ? (
            <>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <ProductTable
                  headings={[
                    "PRODUCT",
                    "CATEGORY",
                    "SUB-CATEGORY",
                    "CODE",
                    "VIEW",
                  ]}
                  setInput={setInput}
                  setSelectMonthFilter={setSelectMonthFilter}
                  data={selectMonthFilter ? output1 : present}
                  period="present"
                  {...props}
                />
              </TabPanel>
            </>
          ) : (
            <ChartSkeleton />
          )}
          <Modal
            centered
            show={showCreate}
            onHide={() => onCloseModal()}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>CREATE A PRODUCT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleCreate}>
                <div className="row mt-3">
                  <div className="col-lg-6 col-12">
                    <Form.Label>Product Name</Form.Label>
                    <Form.Control
                      className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                      placeholder="Product Name"
                      type="text"
                      value={campaignForm.name || ""}
                      onChange={(event) =>
                        setCampaignForm({
                          ...campaignForm,
                          name: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <Form.Label>Product Description</Form.Label>
                    <textarea
                      className="form-control"
                      rows="3"
                      value={campaignForm.description || ""}
                      onChange={(event) =>
                        setCampaignForm({
                          ...campaignForm,
                          description: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-7 col-12">
                    <Form.Label>Product Code</Form.Label>
                    <Form.Control
                      className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                      placeholder="Product Code"
                      type="text"
                      value={campaignForm.code || ""}
                      onChange={(event) =>
                        setCampaignForm({
                          ...campaignForm,
                          code: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-5 col-12 mt-3 mt-lg-0">
                    <Form.Label>Product Price</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      style={{ height: 48 }}
                      className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                      placeholder="Product Price"
                      value={campaignForm.price || ""}
                      onChange={(event) =>
                        setCampaignForm({
                          ...campaignForm,
                          price: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-6 col-12" controlId="category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      style={{ height: 48 }}
                      value={campaignForm.category}
                      onChange={(event) => {
                        setCampaignForm({
                          ...campaignForm,
                          category: event.target.value,
                        });
                        setSubCategories(
                          category.filter(
                            (sub) => sub.category === event.target.value
                          )
                        );
                      }}
                    >
                      <option>Choose a category</option>
                      {category.map((cat, index) => (
                        <option key={index} value={cat.category}>
                          {cat.category}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div
                    className="col-lg-6 col-12 mt-3 mt-lg-0"
                    controlId="subcategory"
                  >
                    <Form.Label>Sub-Category</Form.Label>
                    <Form.Control
                      as="select"
                      style={{ height: 48 }}
                      value={campaignForm.subcategory}
                      onChange={(event) =>
                        setCampaignForm({
                          ...campaignForm,
                          subcategory: event.target.value,
                        })
                      }
                    >
                      {<option>Choose a category first</option>}
                      {!_.isEmpty(campaignForm.category) &&
                        _.isEmpty(subCategories?.[0]?.subcategory) && (
                          <option>There are no subcategories</option>
                        )}
                      {subCategories[0]?.subcategory.map(
                        (subcategory, index) => (
                          <option key={index} value={subcategory}>
                            {subcategory}
                          </option>
                        )
                      )}
                    </Form.Control>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <Form.Label>Product URL</Form.Label>
                    <Form.Control
                      className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                      type="url"
                      placeholder="https://example.com"
                      // pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                      value={campaignForm.url || ""}
                      onChange={(event) =>
                        setCampaignForm({
                          ...campaignForm,
                          url: event?.target?.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <div>
                          <Form.Label>
                            Product Image / Video (50 MB max.)
                          </Form.Label>
                          <Upload
                            {...propsUploadFile}
                            onPreview={handlePreview}
                            accept="image/*,video/*"
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Button
                                className="choose_file_button"
                                onClick={() => setErrorMessage("")}
                              >
                                Choose file
                              </Button>
                            </div>
                          </Upload>
                          {errorMessage && (
                            <div className="mt-1 text-danger">
                              {errorMessage}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <Form.Label>Product Preview</Form.Label>
                        {fileList.length === 0 && (
                          <img
                            src={couponImage}
                            alt="Coupon"
                            width="150px"
                            height="100px"
                          />
                        )}
                        <List
                          grid={{ gutter: 16 }}
                          dataSource={fileList}
                          renderItem={(file) => (
                            <List.Item
                              style={{
                                position: "inherit",
                                zIndex: 0,
                                display: "flex",
                              }}
                            >
                              {file.type.startsWith("image/") ? (
                                <div className="position-relative">
                                  <div
                                    className="upload_preview_cancel_icon"
                                    onClick={() => handleRemoveFile(file)}
                                  >
                                    X
                                  </div>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                    className="border rounded"
                                    style={{ width: "auto", height: "100px" }}
                                  />
                                </div>
                              ) : (
                                <div className="position-relative">
                                  <div
                                    className="upload_preview_cancel_icon"
                                    onClick={() => handleRemoveFile(file)}
                                  >
                                    X
                                  </div>
                                  <video
                                    controls
                                    src={URL.createObjectURL(file)}
                                    className="border rounded"
                                    style={{ width: "auto", height: "100px" }}
                                  />
                                </div>
                              )}
                            </List.Item>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outlined"
                disabled={
                  validateProduct(campaignForm) || loading || errorMessage
                }
                onClick={() => handleCreate()}
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2"
                  />
                )}
                Create Product
              </Button>
              <Button
                variant="outlined"
                onClick={() => resetForm()}
                style={{ marginLeft: "20px" }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Campaigns;
