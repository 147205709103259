import axios from 'axios';
import config from "../config";

const token = localStorage.getItem('token');

const createCoupon = async (coupon) => {
  return axios.post(`${config.apiUrl}/coupons`, coupon, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  })
};

const retrieveCoupon = async (coupon) => {
  return await fetch(`${config.apiUrl}/coupons/${coupon}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/jsoon',
      Authorization: `Bearer ${token}`,
    }
  });
};

const updateCoupon = async (couponId, coupon) => {
  return await fetch(`${config.apiUrl}/coupons/${couponId}`, {
    method: 'PATCH',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(coupon),
  });
};

const updateProduct = async (id, editCoupon, payload) => {


  // return await fetch(`${config.apiUrl}/product/id=${id}`, {
    return await fetch(`${config.apiUrl}/product?id=${id}`, {
    method: 'PUT',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    
    body: JSON.stringify( editCoupon, payload),

  });

};



const deleteCoupon = async (coupon) => {
  return axios.delete(`${config.apiUrl}/coupons/${coupon}`, {
    headers: {
      'content-type': 'application/jsoon',
      Authorization: `Bearer ${token}`,
    }
  });
};

const deleteProduct = async (id) => {
  return axios.delete(`${config.apiUrl}/product?id=${id}`, {
    headers: {
      'content-type': 'application/jsoon',
      Authorization: `Bearer ${token}`,
    }
  });
};

export default {
  createCoupon,
  retrieveCoupon,
  updateCoupon,
  updateProduct,
  deleteCoupon,
  deleteProduct,
};
