import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HomeIcon from "@material-ui/icons/Home";
import { List, Upload } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ChartSkeleton from "../../components/ChartSkeleton";
import PromotionTable from "../../components/Promotion/PromotionTable";
import { getFileDimensions } from "../../helper/getFileDimensions";
import { uploadFileOnS3 } from "../../helper/s3Uploader";
import { validatePromotions } from "../../resources/Validations";
import services from "../../services";

toast.configure();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #f2f2f2",
  },
}));

const Promotions = (props) => {
  const classes = useStyles();
  const { setPage } = props;
  const theme = useTheme();
  const [value, setValue] = React.useState(1);
  const { client } = useSelector((state) => state.client);
  const [promotions, setPromotions] = useState([]);
  const [promotionForm, setPromotionForm] = useState({});
  const [focusedInput, setFocusedInput] = useState(null);
  const [future, setFuture] = useState([]);
  const [past, setPast] = useState([]);
  const [present, setPresent] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (fileList.length > 5) {
      setErrorMessage("You can only upload up to 5 files.");
    }
  }, [fileList]);

  const handlePreview = (file) => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    if (isImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result;
      };
      reader.readAsDataURL(file);
    } else if (isVideo) {
    }
  };

  const handleRemoveFile = (file) => {
    setFileList((prevFileList) => prevFileList.filter((item) => item !== file));
    const filteredImages = promotionForm.images.filter((item) => item !== file);
    setPromotionForm({
      ...promotionForm,
      images: filteredImages,
    });
    setErrorMessage("");
  };

  let tempFile = [];
  const propsUploadFile = {
    onRemove: (file) => {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item !== file)
      );
      const filteredImages = promotionForm.images.filter(
        (item) => item !== file
      );
      setPromotionForm({
        ...promotionForm,
        images: filteredImages,
      });
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        setErrorMessage("File size exceeds the maximum allowed size (50MB).");
        return false;
      }

      // If it's an image or video, add it to the fileList
      if (isImage || isVideo) {
        setFileList((prevFileList) => [...prevFileList, file]);
        tempFile.push(file);

        setPromotionForm({
          ...promotionForm,
          images: promotionForm?.images
            ? [...promotionForm.images, ...tempFile]
            : [...tempFile],
        });
      } else {
        setErrorMessage(
          "Invalid file type. Only images and videos are allowed."
        );
        return false;
      }
      return false;
    },
    fileList,
    multiple: true,
    showUploadList: false,
  };

  const couponImage = require("../../assets/images/logo-placeholder.png");
  const handleChange = (event, newValue) => setValue(newValue);

  const notify = (type, text) =>
    toast[type](text, {
      position: toast.POSITION.TOP_RIGHT,
    });

  const handleCreate = async () => {
    setLoading(true);
    const dimensions = await getFileDimensions(fileList[0]);

    promotionForm.client = client._id;
    promotionForm.campaign_type = "P";

    await uploadFileOnS3(fileList, dimensions, "promotion")
      .then((res) => {
        const files = res.map((item) => {
          return { images_url: item.Location };
        });
        promotionForm.campaign_images = files;

        services.CampaignServices.addPromotion(promotionForm)
          .then((response) => response.json())
          .then((response) => {
            setShowCreate(false);
            setPromotionForm({});
            setFileList([]);
            setPromotions(response);
            getPromotions();
            setLoading(false);
            notify("success", "Promotion created successfully.");
            setErrorMessage("");
          })
          .catch((err) => {
            notify("error", "Something went to wrong.");
            setLoading(false);
            setErrorMessage("");
          });
      })
      .catch((err) => {
        console.log("errrrrr", err);
        notify("error", "Something went to wrong.");
        setLoading(false);
        setErrorMessage("");
      });
  };

  const enable_disable_promotion = async (promotion_id, enable_toggle) => {
    var status_value = enable_toggle;
    await services.CampaignServices.enable_disable_promotion(
      promotion_id,
      status_value
    )
      .then((response) => response.json())
      .then((response) => {
        var msg = "";
        if (enable_toggle == true) {
          msg = "Promotion Disabled Successfully";
        } else {
          msg = "Promotion Enabled Successfully";
        }
        notify("success", msg);
        getPromotions();
      });
  };

  const getPromotions = () => {
    const current = new Date().toUTCString();
    const apast = [],
      apresent = [],
      afuture = [];

    services.CampaignServices.getPromotions()
      .then((res) => res.json())
      .then((res) => {
        setPromotions(res.data.promotions);
        res.data.promotions.map((campaign) => {
          const campaign_start_date = new Date(
            campaign.campaign_start_date
          ).toUTCString();
          const campaign_expire_date = new Date(
            campaign.campaign_expiration_date
          ).toUTCString();
          if (moment(campaign_expire_date).isBefore(current))
            apast.push(campaign); //Populate past campaigns
          if (
            moment(campaign_start_date).isSameOrBefore(current) &&
            moment(campaign_expire_date).isSameOrAfter(current)
          )
            apresent.push(campaign); // Populate present campaigns
          if (moment(campaign_start_date).isAfter(current))
            afuture.push(campaign); // Populate future campaigns
          return true;
        });
        setPast(apast);
        setPresent(apresent);
        setFuture(afuture);
      })
      .catch((err) => console.log("getPromotions error", err));
  };

  useEffect(() => {
    getPromotions();
  }, []);

  const resetForm = () => {
    setShowCreate(false);
    setPromotionForm({});
    setFileList([]);
    setErrorMessage("");
  };

  const onCloseModal = () => {
    setPromotionForm({});
    setFileList([]);
    setShowCreate(false);
    setLoading(false);
    setErrorMessage("");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Promotions</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div className="row my-3 align-items-center">
        <div className="col-md-7 col-12">
          <h4>Promotions ({promotions.length})</h4>
        </div>
        <div className="col-md-5 col-12 text-left text-md-right">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<AddCircleIcon />}
            onClick={(_) => setShowCreate(true)}
          >
            {" "}
            PROMOTION
          </Button>
        </div>
      </div>
      <div
        className={"col pt-3 " + classes.root}
        style={{ paddingLeft: 0, paddingRight: "0" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          style={{ overflow: "auto" }}
        >
          <Tab
            style={{ minWidth: "230px" }}
            label={"Past Promotions (" + past.length + ")"}
            {...a11yProps(0)}
          />
          <Tab
            style={{ minWidth: "230px" }}
            label={"Current Promotions (" + present.length + ")"}
            {...a11yProps(1)}
          />
          <Tab
            style={{ minWidth: "230px" }}
            label={"Future Promotions (" + future.length + ")"}
            {...a11yProps(2)}
          />
        </Tabs>
        {past ? (
          <>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <PromotionTable
                headings={[
                  "Promotions",
                  "Promotion Enabled",
                  "Expired",
                  "View",
                ]}
                data={past}
                period="past"
                {...props}
                enable_disable_promotion={enable_disable_promotion}
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <PromotionTable
                headings={[
                  "Promotions",
                  "Promotion Enabled",
                  "Expires",
                  "View",
                ]}
                data={present}
                period="present"
                {...props}
                enable_disable_promotion={enable_disable_promotion}
              />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <PromotionTable
                headings={["Promotions", "Promotion Enabled", "Starts", "View"]}
                data={future}
                period="future"
                {...props}
                enable_disable_promotion={enable_disable_promotion}
              />
            </TabPanel>
          </>
        ) : (
          <ChartSkeleton />
        )}
        <Modal
          centered
          show={showCreate}
          onHide={() => onCloseModal()}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>CREATE A PROMOTION</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row mt-3">
                <div className="col-lg-6 col-12">
                  <Form.Group>
                    <Form.Label>Promotion Name</Form.Label>
                    <Form.Control
                      className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                      placeholder="Promotion Name"
                      type="text"
                      value={promotionForm.campaign_name || ""}
                      onChange={(event) =>
                        setPromotionForm({
                          ...promotionForm,
                          campaign_name: event.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6 col-12">
                  <Form.Group>
                    <Form.Label>Promotion Run Period</Form.Label>
                    <DateRangePicker
                      orientation={
                        window.innerWidth >= 768 ? "horizontal" : "vertical"
                      }
                      startDateId="startDate"
                      endDateId="endDate"
                      startDate={promotionForm.campaign_start_date}
                      endDate={promotionForm.campaign_expiration_date}
                      onDatesChange={({ startDate, endDate }) =>
                        setPromotionForm({
                          ...promotionForm,
                          campaign_start_date: startDate,
                          campaign_expiration_date: endDate,
                        })
                      }
                      focusedInput={focusedInput}
                      onFocusChange={(focused) => setFocusedInput(focused)}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <Form.Label>Promotion Description</Form.Label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={promotionForm.campaign_description || ""}
                    onChange={(event) =>
                      setPromotionForm({
                        ...promotionForm,
                        campaign_description: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div>
                        <Form.Label>
                          Promotions Image / Video (50 MB max.)
                        </Form.Label>
                        <Upload
                          {...propsUploadFile}
                          onPreview={handlePreview}
                          accept="image/*,video/*"
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Button
                              className="choose_file_button"
                              onClick={() => setErrorMessage("")}
                            >
                              Choose file
                            </Button>
                          </div>
                        </Upload>
                        {errorMessage && (
                          <div className="mt-1 text-danger">{errorMessage}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <Form.Label>Promotion Preview</Form.Label>
                      {fileList.length === 0 && (
                        <img
                          src={couponImage}
                          alt="Coupon"
                          width="150px"
                          height="100px"
                        />
                      )}
                      <List
                        grid={{ gutter: 16 }}
                        dataSource={fileList}
                        renderItem={(file) => (
                          <List.Item style={{ position: "inherit", zIndex: 0 }}>
                            {file.type.startsWith("image/") ? (
                              <div className="position-relative">
                                <div
                                  className="upload_preview_cancel_icon"
                                  onClick={() => handleRemoveFile(file)}
                                >
                                  X
                                </div>
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={file.name}
                                  className="border rounded"
                                  style={{ width: "auto", height: "100px" }}
                                />
                              </div>
                            ) : (
                              <div className="position-relative">
                                <div
                                  className="upload_preview_cancel_icon"
                                  onClick={() => handleRemoveFile(file)}
                                >
                                  X
                                </div>
                                <video
                                  controls
                                  src={URL.createObjectURL(file)}
                                  className="border rounded"
                                  style={{ width: "auto", height: "100px" }}
                                />
                              </div>
                            )}
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outlined"
              disabled={
                validatePromotions(promotionForm) || loading || errorMessage
              }
              onClick={() => handleCreate()}
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              Create Promotion
            </Button>
            <Button
              variant="outlined"
              onClick={() => resetForm()}
              style={{ marginLeft: "20px" }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Promotions;
