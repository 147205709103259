export const getFileDimensions = async (file) => {

    const isImage = file.type.startsWith('image');

    const mediaElement = isImage
        ? document.createElement('img')
        : document.createElement('video');

    mediaElement.src = URL.createObjectURL(file);

    return new Promise((resolve, reject) => {
        if (isImage) {
            mediaElement.onload = () => {
                const tempDimensions = {
                    width: mediaElement.width,
                    height: mediaElement.height,
                };

                URL.revokeObjectURL(mediaElement.src);
                resolve(tempDimensions);
            };

            mediaElement.onerror = (error) => {
                reject(error);
            };

        } else {
            mediaElement.onloadedmetadata = () => {
                const tempDimensions = {
                    width: mediaElement.videoWidth,
                    height: mediaElement.videoHeight,
                };

                URL.revokeObjectURL(mediaElement.src);
                resolve(tempDimensions);
            };

            mediaElement.onerror = (error) => {
                reject(error);
            };
        }
    });
}