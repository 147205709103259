import Chip from '@material-ui/core/Chip';
import _ from "lodash";
import moment from 'moment';
import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
import Logo from '../../assets/images/CoupifyLogo-2.png';
import Stripe from '../../assets/images/stripe-logo.png';
import services from '../../services';

class TableComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      client: JSON.parse(localStorage.getItem('client')),
      usage: [],
      total: 0,
      currentMonth: {
        start: moment().startOf('month').format("MMM Do"),
        end: moment().endOf('month').format("MMM Do, YYYY")
      }
    }
  }

  componentDidMount() {
    services.UsageServices.getInvoiceUsage(this.state.client._id)
      .then(res => res.json())
      .then(res => {
        if (res.err) return false;
        res.data.usage.forEach(use => this.setState({ total: this.state.total + use.count }));
        this.setState({ usage: res.data.usage });
      });
  }

  render() {
    const client = this.state.client;
    return (
      <>
        <div className="row mb-4">
          <div className="col">
            <img src={Logo} width="200" alt="" />
            {/* <div>Coupify</div> */}
            <div>Nashville, Tn 37211</div>
            {/* <div><a className="text-secondary" href="mailto:sales@coupify.io" target="_top">sales@coupify.io</a></div> */}
            <div><a className="text-secondary" href="mailto:apps@xclusit.com" target="_top">apps@xclusit.com</a></div>
            <div>615.555.1234</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12">
            <h4 className="m-0" style={{ textTransform: 'capitalize' }}>{client.client_name}</h4>
            <span className="m-0 pt-0">{client.first_name} {client.last_name}</span>
            <br />
            <span className="m-0">{client.address}</span>
            <p className="m-0">{client.phone}</p>
            <p className='mb-0'>
              <a className="text-secondary" href={`mailto:${client.email}`} target="_top">{client.email}</a>
            </p>
            <p>
              <a href={client.website} rel="noopener noreferrer" target="_blank">{client.website}</a>
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <h4>Dates</h4>
            <p>{this.state.currentMonth.start} - {this.state.currentMonth.end}</p>
            <h4 className='pt-1'>Status</h4>
            <p>
              <Chip label="Active" />
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <h4>Invoice Number</h4>
            <p>12435313490</p>
            <h4 className='pt-1'>Total Due</h4>
            <p>
              <CurrencyFormat value={parseInt(this.state.total) * .75} displayType={'text'} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={'$'} />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col table-responsive">
            <table className="table table-striped table-hover invoice-detail-table">
              <thead>
                <tr className="thead-default">
                  <th>Offer</th>
                  <th>Redemptions</th>
                  <th>Amount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {_.isEmpty(this.state.usage) ? (
                  <tr><td>No Usages this month</td></tr>
                ) : (
                  this.state.usage.map((use, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <h6>{use.name[0]}</h6>
                        </td>
                        <td>{use.count}</td>
                        <td>$0.75</td>
                        <td>
                          <CurrencyFormat value={parseInt(use.count) * .75} displayType={'text'} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={'$'} />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <img src={Stripe} width="300" alt="" />
          </div>
        </div>
      </>
    );
  }
}

export default TableComponent;
